import React from 'react';
import { connect } from 'react-redux';
import { showNotice } from '../../../../src/notice/Actions';
import { selectContructionSite } from '../../../../src/constructionSiteSelect/Actions';
import { selectContract } from '../../../../src/contractSelect/Actions';
import { fetch } from '../utils';
import { XYPlot, YAxis, LineSeries } from 'react-vis';
import "../../../node_modules/react-vis/dist/style.css";
import './ConstructionSiteLatestSelect.css'

class ConstructionSiteLatestSelect extends React.Component {
  constructor(props) {
    super(props);

    this.site = null;

    this.state = {
      constructionSites: [],
      graphReady: false,
      firstTime: true,
      draw: false,
      loading: true
    };

    this.selectContructionSite = this.selectContructionSite.bind(this);
  }

  async UNSAFE_componentWillMount() {
    const url = '/' + this.props.get;
    let count = 0;
    let constructionSites = [];

    try {
      const sites = await fetch('/constructionsites');
      const targetCount = sites.length < 5 ? sites.length : 5;
      const data = await fetch(url);

      for (let d = 0; d < data.length; d++) {
        const mass = data[d];

        if (count === 0) {
          constructionSites.push(mass['construction_site']['id']);
          count++;
        }
        else if (mass['construction_site']['id'] !== constructionSites[count - 1]) {
          let same = false;

          for (let i = count - 2; i >= 0; i--) {
            if (mass['construction_site']['id'] === constructionSites[i]) {
              same = true;
              break;
            }
          }

          if (same) continue;

          constructionSites.push(mass['construction_site']['id']);
          count++;

          if (count === targetCount) break;
        }
      }

      let gotSites = [];

      for (let i = 0; i < count; i++) {
        const site = sites.find(site => site['id'] === constructionSites[i]);

        if (site == null) {
          continue;
        }
        
        gotSites.push(site);
      }

      this.setState({
        constructionSites: gotSites,
        loading: false
      });

      for (let i = 0; i < gotSites.length; i++) {
        const site = gotSites[i];
        let graphData = [];
        let graphData2 = [];
        let currentCount = 0;

        const objects = (await fetch(url + '?site=' + site['id'])).reverse();
        const paths = await fetch('/paths/mass/site/' + site['id']);

        for (let index = 0; index < objects.length; index++) {
          const object = objects[index];

          const pathsOfMass = paths.filter(path => path.paths_id === object.paths_id);
          let massWholeArea = 0;

          for (let index in pathsOfMass) {
            const path = pathsOfMass[index];
            if (path.area) {
              massWholeArea += path.area;
            }
          }

          if (massWholeArea !== 0) {
            let weightedSumMassPerSquare = 0;

            for (let index in pathsOfMass) {
              const path = pathsOfMass[index];
              if (path.area) {
                const ratio = path.area / massWholeArea;
                weightedSumMassPerSquare += path.mass_per_square * ratio;
              }
            }

            object.calculated_kg_per_square = object.mass * 1000 / massWholeArea;
            graphData.push({x: currentCount, y: object.calculated_kg_per_square});
            graphData2.push({x: currentCount, y: weightedSumMassPerSquare});
            currentCount++;
          }
        }

        const propertyName = 'graphData-' + site['id'];
        const propertyName2 = 'graphData2-' + site['id'];

        this.setState({
          [propertyName]: graphData,
          [propertyName2]: graphData2
        });
      }
    } catch(error) {
      console.log('Getting five last construction sites failed: ' + error, 'Error');
    }
  }

  UNSAFE_componentWillUpdate(props, state) {
    if (this.site != null && props.constructionSites !== this.props.constructionSites) {
      props.selectContructionSite(this.site);
      localStorage.site = this.site['id'];
    }
  }

  async selectContructionSite(site) {
    await this.props.toggleSelectingSite();
    this.site = site;
    const contract = site['contract']['id'];
    this.props.selectContract(contract);
    this.props.selectContructionSite(site);
    localStorage.contract = contract;
    await this.props.toggleSelectingSite();
  }

  async getPaths(id) {
    const paths = await fetch('/paths/' + id);
    return paths;
  }

  render() {
    if (this.state.loading) return <div className='loader'/>;

    if (this.state.constructionSites.length === 0) return null;

    return (
      <div>
        <h4>Viimeisimmät kohteet</h4>
        <div id='construction-site-container'>
          { this.state.constructionSites.map(site => {
            const selected = this.props.selectedConstructionSite ? this.props.selectedConstructionSite.get('id') === site['id'] : false;
            return (
              <div key={site['name']} className='construction-site-select-container'
                    onClick={selected ? null : this.selectContructionSite.bind(null, site)}
                   style={{margin: 'auto'}}>
                <div className={'construction-site-select' + (selected ? ' selected' : '')}>
                  {site['contract']['name']}
                  <br />
                  {site['name']}
                </div>
                { this.state['graphData-' + site['id']] == null ?
                  <div className='loader' />
                  :
                  <XYPlot height={150} width={285}>
                    <YAxis />
                    <LineSeries color="blue" data={this.state['graphData-' + site['id']]} />
                    <LineSeries color="green" data={this.state['graphData2-' + site['id']]} />
                  </XYPlot>
                }
              </div>
            );
          })
          }
        </div>
      </div>
    );
  };
}

export default connect(state => ({
  constructionSites: state.constructionSiteSelect.get('constructionSites'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
}), { showNotice, selectContructionSite, selectContract })(ConstructionSiteLatestSelect);
