import React from 'react';
import { connect } from 'react-redux';
import { XYPlot, YAxis, LineSeries, Crosshair, DiscreteColorLegend } from 'react-vis';
import { showConfirm } from '../../../../src/confirm/Actions';
import { showNotice } from '../../../../src/notice/Actions';
import List from '../../../../src/list/List';
import Excel from '../../../../src/excel/Excel';
import { fetch, paddedNumber, timer } from '../utils';
import config from '../../settings/config';
import { fromJS } from 'immutable';
import './Sensor.css';

var CryptoJS = require("crypto-js");
var XLSX = require("xlsx");


const WidthSensor = props => {
  if (props.loading) {
    return <div className='loader' />
  }
  const widths = fromJS(props.widths);

  return (
    <div>
      <WidthGraph graphData={props.graphData}
        crosshairValue={props.crosshairValue}
        setCrosshairValue={props.setCrosshairValue}
        resetCrosshairValue={props.resetCrosshairValue}
        showAmount={props.showAmount}
        amount={props.dataFilterAmount}
        width={props.width} height={props.height} />
      <List emptyText={'Ei dataa'}
        header={['Aika', 'Tie', 'Tieosa', 'Paalu', 'Leveys', '']}
        fields={['time#time', 'road_number', 'road_part', 'road_distance', 'width',
          'button|Näytä massa|0#this']}
        functions={[props.selectMass]}
        data={widths} />
      <Excel show={props.makeExcel} toggle={props.toggleMakeExcel}
        name={props.name + ' - Leveydet'}
        headers={['Aika', 'Latitude', 'Longitude', 'Tieosoite', 'Leveys']}
        dataHeaders={['time', 'latitude', 'longitude', 'roadData', 'width']}
        timeField={'time'}
        data={widths}
        fileType={'csv'} />
    </div>
  )
};

const WidthGraph = props => {
  if (!props.graphData) {
    return null;
  }
  const crosshair = props.crosshairValue[0] != null ?
    <div id='crosshair-data'>
      <span>{props.crosshairValue[0].time}</span>
      <br />
      {props.crosshairValue[0].roadPart ?
        <span>
          {props.crosshairValue[0].roadPart} / {props.crosshairValue[0].roadDistance}
        </span>
        :
        null
      }
      <br />
      <span>{props.crosshairValue[0].width} Leveys (m)</span>
    </div>
    : null;

  return (
    <div className='center'>
      <XYPlot height={props.height * 0.3} width={props.width * 0.8} onMouseLeave={props.resetCrosshairValue}
        yPadding={20}>
        <YAxis />
        <LineSeries color="blue" data={props.graphData[0]}
          onNearestX={props.setCrosshairValue} />
        <Crosshair values={props.crosshairValue}>
          {crosshair}
        </Crosshair>
      </XYPlot>
    </div>
  );
}

const DeepnessSensor = props => {
  if (props.loading) {
    return <div className='loader' />
  }

  const deepnesses = fromJS(props.deepnesses);

  return (
    <div>
      <DeepnessGraph graphData={props.graphData}
        crosshairValue={props.crosshairValue}
        setCrosshairValue={props.setCrosshairValue}
        resetCrosshairValue={props.resetCrosshairValue}
        showAmount={props.showAmount}
        amount={props.dataFilterAmount}
        width={props.width} height={props.height}
        show={props.show} sensors={props.sensors} />
      <List emptyText={'Ei dataa'}
        header={['Aika', 'Tie', 'Tieosa', 'Paalu', 'Sijainti', 'Syvyys (mm)', '']}
        fields={['time#time', 'road_number', 'road_part', 'road_distance',
          'location', 'deepness', 'button|Näytä massa|0#this']}
        functions={[props.selectMass]}
        data={deepnesses} />
      <Excel show={props.makeExcel} toggle={props.toggleMakeExcel}
        name={props.name + ' - Syvyydet'}
        headers={['Aika', 'Latitude', 'Longitude', 'Tieosoite', 'Sensori sijainti', 'Syvyys (mm)']}
        dataHeaders={['time', 'latitude', 'longitude', 'roadData', 'location',
          'deepness']}
        timeField={'time'}
        data={deepnesses}
        fileType={'csv'} />
    </div>
  )
};

const DeepnessGraph = props => {
  const crosshair = props.crosshairValue[0] != null ?
    <div id='crosshair-data'>
      <span>{props.crosshairValue[0].time}</span>
      <br />
      <span>{props.crosshairValue[0].roadPart} / {props.crosshairValue[0].roadDistance}</span>
      <br />
      <span>{props.crosshairValue[0].leftDeepness} Vasen (mm)</span>
      <br />
      <span>{props.crosshairValue[0].rightDeepness} Oikea (mm)</span>
    </div>
    : null;

  const ITEMS = [
    { title: 'Vasen', color: "blue", strokeDasharray: "dashed" },
    { title: 'Oikea', color: 'green', strokeDasharray: "dashed" },
  ];

  return (
    <div className='center'>
      <DiscreteColorLegend orientation="horizontal" width={300} items={ITEMS} />
      {'Näytä: '}
      <label>
        Vasen
        <input type='checkbox' name='side' value='1'
          onChange={props.show.bind(this, 1)}
          checked={props.sensors.includes(1)} />
      </label>
      <label>
        Oikea
        <input type='checkbox' name='side' value='2'
          onChange={props.show.bind(this, 2)}
          checked={props.sensors.includes(2)} />
      </label>
      <XYPlot height={props.height * 0.3} width={props.width * 0.8} onMouseLeave={props.resetCrosshairValue}
        yPadding={20} xType="time">
        <YAxis />
        {props.sensors.includes(1) ?
          <LineSeries color="blue" data={props.graphData[0]}
            onNearestX={props.setCrosshairValue} />
          : null
        }
        {props.sensors.includes(2) ?
          <LineSeries color="green" data={props.graphData[1]}
            onNearestX={props.setCrosshairValue} />
          : null
        }
        <Crosshair values={props.crosshairValue}>
          {crosshair}
        </Crosshair>
      </XYPlot>
    </div>
  );
}

const TemperatureSensor = props => {
  if (props.loading) {
    return <div className='loader' />
  }

  const temperatures = fromJS(props.temperatures);

  return (
    <div>
      <TemperatureGraph graphData={props.graphData}
        crosshairValue={props.crosshairValue}
        setCrosshairValue={props.setCrosshairValue}
        resetCrosshairValue={props.resetCrosshairValue}
        showAmount={props.showAmount}
        amount={props.dataFilterAmount}
        width={props.width} height={props.height}
        show={props.show} sensors={props.sensors} />
      <List emptyText={'Ei dataa'}
        header={['Aika', 'Tie', 'Tieosa', 'Paalu', 'Sijainti', 'Lämpötila', '']}
        fields={['time#time', 'road_number', 'road_part', 'road_distance', 'location',
          'temperature#integer']}
        data={temperatures} />
      <Excel show={props.makeExcel} toggle={props.toggleMakeExcel}
        name={props.name + ' - Lämpötilat'}
        headers={['Aika', 'Latitude', 'Longitude', 'Tieosoite', 'Sensori sijainti', 'Lämpötila']}
        dataHeaders={['time', 'latitude', 'longitude', 'roadData', 'location',
          'temperature']}
        timeField={'time'}
        data={temperatures}
        fileType={'csv'} />
    </div>
  )
};

const TemperatureGraph = props => {
  const crosshair = props.crosshairValue[0] != null ?
    <div id='crosshair-data'>
      <span>{props.crosshairValue[0].time}</span>
      <br />
      <span>{props.crosshairValue[0].roadPart} / {props.crosshairValue[0].roadDistance}</span>
      <br />
      <span>Vasen: {Math.round(props.crosshairValue[0].leftTemperature)} C°</span>
      <br />
      <span>Keski:{Math.round(props.crosshairValue[0].middleTemperature)} C°</span>
      <br />
      <span>Oikea: {Math.round(props.crosshairValue[0].rightTemperature)} C°</span>
      <br />
      <span>REM: {Math.round(props.crosshairValue[0].REMTemperature)} C°</span>
    </div>
    : null;

  const ITEMS = [
    { title: 'Vasen', color: "blue", strokeDasharray: "dashed" },
    { title: 'Keski', color: 'green', strokeDasharray: "dashed" },
    { title: 'Oikea', color: 'purple', strokeDasharray: "dashed" },
    { title: 'REM', color: 'red', strokeDasharray: "dashed" },
  ];

  return (
    <div className='center'>
      <DiscreteColorLegend orientation="horizontal" width={300} items={ITEMS} />
      {'Näytä: '}
      <label>
        Vasen
        <input type='checkbox' name='sensor' value='1'
          onChange={props.show.bind(this, 1)}
          checked={props.sensors.includes(1)} />
      </label>
      <label>
        Keski
        <input type='checkbox' name='sensor' value='2'
          onChange={props.show.bind(this, 2)}
          checked={props.sensors.includes(2)} />
      </label>
      <label>
        Oikea
        <input type='checkbox' name='sensor' value='3'
          onChange={props.show.bind(this, 3)}
          checked={props.sensors.includes(3)} />
      </label>
      <label>
        REM
        <input type='checkbox' name='sensor' value='4'
          onChange={props.show.bind(this, 4)}
          checked={props.sensors.includes(4)} />
      </label>
      <XYPlot height={props.height * 0.3} width={props.width * 0.8} onMouseLeave={props.resetCrosshairValue}
        yPadding={20} xType="time">
        <YAxis />
        {props.sensors.includes(1) && props.graphData ?
          <LineSeries color="blue" data={props.graphData[0]}
            onNearestX={props.setCrosshairValue} />
          : null
        }
        {props.sensors.includes(2) && props.graphData ?
          <LineSeries color="green" data={props.graphData[1]}
            onNearestX={props.setCrosshairValue} />
          : null
        }
        {props.sensors.includes(3) && props.graphData ?
          <LineSeries color="purple" data={props.graphData[2]}
            onNearestX={props.setCrosshairValue} />
          : null
        }
        {props.sensors.includes(4) && props.graphData ?
          <LineSeries color="red" data={props.graphData[3]}
            onNearestX={props.setCrosshairValue} />
          : null
        }
        <Crosshair values={props.crosshairValue}>
          {crosshair}
        </Crosshair>
      </XYPlot>
    </div>
  );
}

class Sensors extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSensor: 0,
      loading: false,
      widths: [],
      deepnesses: [],
      temperatures: [],
      makeExcel: false,
      crosshairValue: [],
      dataFilterAmount: 1,
      sensors: [],
      allDataExcel: false,
      loadingAllDataExcel: false,
      loadingAllDataMetaExcel: false
    };

    this.allDataHeaders = ['Päivämäärä', 'Aikaleima', 'Latitude', 'Longitude', 'Tieosoite', 'Jyrsinsyv_oik',
      'Jyrsinsyv_vas', 'L_al', 'L_oik', 'L_kes', 'L_vas', 'Leveys'];

    this.metaHeaders = ['Tilaaja', 'Urakoitsija', 'Urakka', 'Kohdenumero', 'Paaluvali', 'Kaista', 'Kaistatarkenne',
      'Mittauspaiva', 'Raportointipaiva', 'Koordinaattijarjestelma', 'Menetelma',
      'Paikannus', 'Jyrsinsyvyysanturi', 'Kaltevuusanturi', 'Lampotila-anturi',
      'Leveysanturi'];

    this.metaDataHeaders = ['orderer', 'organization', 'contract', 'custom_id', 'distanceInterval', 'lane',
      'laneDetail', 'dataDate', 'reportDate', 'coordinateSystem', 'operationType',
      'locationSensor', 'deepnessSensor', 'angleSensor', 'temperatureSensor',
      'widthSensor'];

    this.showSensor = this.showSensor.bind(this);
    this.toggleMakeExcel = this.toggleMakeExcel.bind(this);
    this.setWidthCrosshairValue = this.setWidthCrosshairValue.bind(this);
    this.setDeepnessCrosshairValue = this.setDeepnessCrosshairValue.bind(this);
    this.setTemperatureCrosshairValue = this.setTemperatureCrosshairValue.bind(this);
    this.resetCrosshairValue = this.resetCrosshairValue.bind(this);
    this.showAmount = this.showAmount.bind(this);
    this.show = this.show.bind(this);
    this.toggleAllDataExcel = this.toggleAllDataExcel.bind(this);
    this.toggleAllDataMetaExcel = this.toggleAllDataMetaExcel.bind(this);
    this.makeAllDataExcel = this.makeAllDataExcel.bind(this);
    this.makeAllDataMetaExcel = this.makeAllDataMetaExcel.bind(this);
    this.confirmDeleteSelectedData = this.confirmDeleteSelectedData.bind(this);
    this.deleteSelectedData = this.deleteSelectedData.bind(this);
    this.sendDataToVayla = this.sendDataToVayla.bind(this);
  }

  componentDidMount () {
    if (this.props.selectedConstructionSite == null) return;

    const name = this.props.selectedConstructionSite.get('name').length > 20 ?
      this.props.selectedConstructionSite.get('id')
      : this.props.selectedConstructionSite.get('name');

    this.setState({ name: name });

    if (this.props.timeRangeStart !== '' && this.props.timeRangeEnd !== '') {
      this.getWidths(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
      this.getDeepnesses(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
      this.getTemperatures(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
    }
    else {
      this.getWidths(this.props.selectedConstructionSite.get('id'));
      this.getDeepnesses(this.props.selectedConstructionSite.get('id'));
      this.getTemperatures(this.props.selectedConstructionSite.get('id'));
    }
  }

  componentDidUpdate (lastProps, lastState) {
    if (this.props.selectedConstructionSite === lastProps.selectedConstructionSite &&
      this.props.timeRangeStart === lastProps.timeRangeStart &&
      this.props.timeRangeEnd === lastProps.timeRangeEnd &&
      this.state.showSensor === lastState.showSensor) {
      return;
    }

    if (this.props.selectedContract == null || this.props.selectedConstructionSite == null) {
      this.setState({
        widths: [],
        deepnesses: [],
        temperatures: [],
        widthGraphData: [[]],
        widthVisibleGraphData: [[]],
        deepnessGraphData: [[], []],
        deepnessVisibleGraphData: [[], []],
        temperatureGraphData: [[], [], [], []],
        temperatureVisibleGraphData: [[], [], [], []]
      })
      return;
    }

    if (this.props.selectedConstructionSite !== lastProps.selectedConstructionSite) {
      const name = this.props.selectedConstructionSite.get('name').length > 20 ?
        this.props.selectedConstructionSite.get('id')
        : this.props.selectedConstructionSite.get('name');

      this.setState({ name: name });
    }
    else if (this.props.timeRangeStart === lastProps.timeRangeStart &&
      this.props.timeRangeStart === lastProps.timeRangeStart) {
      return;
    }

    if (this.props.timeRangeStart !== '' && this.props.timeRangeEnd !== '') {
      this.getWidths(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
      this.getDeepnesses(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
      this.getTemperatures(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
    }
    else {
      this.getWidths(this.props.selectedConstructionSite.get('id'));
      this.getDeepnesses(this.props.selectedConstructionSite.get('id'));
      this.getTemperatures(this.props.selectedConstructionSite.get('id'));
    }
  }

  componentWillUnmount () {
    if (this.socket != null) this.socket.close();
  }

  getWidths (site, startTime, endTime) {
    this.setState({
      loadingWidth: true,
      dataFilterAmount: 1
    });

    let url;

    if (startTime != null) {
      url = '/width?site=' + site + '&timestart=' + startTime
        + '&timeend=' + endTime;
    }
    else {
      url = '/width?site=' + site;
    }

    fetch(url).then(data => {
      data = data.sort((a, b) => {
        return new Date(a.time) - new Date(b.time);
      });

      let graphData = [[]];

      for (let index in data) {
        const width = data[index];
        const x = new Date(width.time).getTime();
        graphData[0].push({ x: x, y: width.width });

        if (width.road_number != null) {
          width.roadData = width.road_number + '/' + width.road_part + '/' + width.road_distance;
        }
        else {
          width.roadData = '-';
        }
      }

      this.setState({
        widths: data,
        widthGraphData: graphData,
        widthVisibleGraphData: graphData
      })
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      this.setState({ loadingWidth: false })
    });
  }

  getDeepnesses (site, startTime, endTime) {
    this.setState({
      loadingDeepnesses: true,
      dataFilterAmount: 1,
      sensors: [1, 2]
    });

    let url;

    if (startTime != null) {
      url = '/deepness?site=' + site + '&timestart=' + startTime
        + '&timeend=' + endTime;
    }
    else {
      url = '/deepness?site=' + site;
    }

    fetch(url).then(data => {
      data = data.sort((a, b) => {
        return new Date(a.time) - new Date(b.time);
      });

      let graphData = [[], []];

      for (let index in data) {
        const deepness = data[index];

        if (deepness.sensor_id === 0) {
          deepness.location = 'Vasen';
        }
        else {
          deepness.location = 'Oikea';
        }

        if (deepness.road_number != null) {
          deepness.roadData = deepness.road_number + '/' + deepness.road_part + '/' + deepness.road_distance;
        }
        else {
          deepness.roadData = '-';
        }

        const x = new Date(deepness.time).getTime();

        if (deepness.sensor_id === 0) {
          graphData[0].push({ x: x, y: deepness.deepness });
        }
        else {
          graphData[1].push({ x: x, y: deepness.deepness });
        }
      }

      this.setState({
        deepnesses: data,
        deepnessGraphData: graphData,
        deepnessVisibleGraphData: graphData
      });
    }).catch(error => {

    }).finally(() => {
      this.setState({ loadingDeepnesses: false })
    });
  }

  getTemperatures (site, startTime, endTime) {
    this.setState({
      loadingTemperatures: true,
      dataFilterAmount: 1,
      sensors: [1, 2, 3, 4]
    });

    let url;

    if (startTime != null) {
      url = '/temperature?site=' + site + '&timestart=' + startTime
        + '&timeend=' + endTime;
    }
    else {
      url = '/temperature?site=' + site;
    }

    fetch(url).then(data => {
      data = data.sort((a, b) => {
        return new Date(a.time) - new Date(b.time);
      });

      let graphData = [[], [], [], []];

      for (let index in data) {
        const temperature = data[index];

        if (temperature.sensor_id === 0) {
          temperature.location = 'Vasen';
        }
        else if (temperature.sensor_id === 1) {
          temperature.location = 'Keski';
        }
        else if (temperature.sensor_id === 2) {
          temperature.location = 'Oikea';
        }
        else {
          temperature.location = 'REM';
        }

        if (temperature.road_number != null) {
          temperature.roadData = temperature.road_number + '/' + temperature.road_part + '/' + temperature.road_distance;
        }
        else {
          temperature.roadData = '-';
        }

        const x = new Date(temperature.time).getTime();

        if (temperature.sensor_id === 0) {
          graphData[0].push({ x: x, y: temperature.temperature });
        }
        else if (temperature.sensor_id === 1) {
          graphData[1].push({ x: x, y: temperature.temperature });
        }
        else if (temperature.sensor_id === 2) {
          graphData[2].push({ x: x, y: temperature.temperature });
        }
        else {
          graphData[3].push({ x: x, y: temperature.temperature });
        }
      }

      this.setState({
        temperatures: data,
        temperatureGraphData: graphData,
        temperatureVisibleGraphData: graphData
      })
    }).catch(error => {

    }).finally(() => {
      this.setState({ loadingTemperatures: false })
    });
  }

  showSensor (sensor) {
    this.setState({
      showSensor: sensor
    });
  }

  toggleMakeExcel () {
    this.setState({ makeExcel: !this.state.makeExcel })
  }

  toggleAllDataExcel () {
    this.setState({ allDataExcel: !this.state.allDataExcel })
  }

  toggleAllDataMetaExcel () {
    this.setState({ allDataMetaExcel: !this.state.allDataMetaExcel })
  }

  getAllData () {
    let widths = [];

    this.state.widths.map(x => widths.filter(a =>
      a.roadData === x.roadData).length > 0 ? null : widths.push(x));

    let deepnesses = [];
    this.state.deepnesses.map(x => deepnesses.filter(a =>
      a.roadData === x.roadData && a.sensor_id === x.sensor_id).length > 0 ? null : deepnesses.push(x));

    const leftDeepnesses = deepnesses.filter(d => d.sensor_id === 0);
    const rightDeepnesses = deepnesses.filter(d => d.sensor_id === 1);

    let temperatures = [];
    this.state.temperatures.map(x => temperatures.filter(a =>
      a.roadData === x.roadData && a.sensor_id === x.sensor_id).length > 0 ? null : temperatures.push(x));

    const leftTemperatures = temperatures.filter(d => d.sensor_id === 0);
    const middleTemperatures = temperatures.filter(d => d.sensor_id === 1);
    const rightTemperatures = temperatures.filter(d => d.sensor_id === 2);
    const REMTemperatures = temperatures.filter(d => d.sensor_id === 3);

    const maxLength = Math.max(widths.length, leftDeepnesses.length,
      rightDeepnesses.length, leftTemperatures.length,
      middleTemperatures.length, rightTemperatures.length,
      REMTemperatures.length);

    let mostValues;

    if (maxLength === widths.length) {
      mostValues = widths;
    }
    else if (maxLength === leftDeepnesses.length) {
      mostValues = leftDeepnesses;
    }
    else if (maxLength === rightDeepnesses.length) {
      mostValues = rightDeepnesses;
    }
    else if (maxLength === leftTemperatures.length) {
      mostValues = leftTemperatures;
    }
    else if (maxLength === middleTemperatures.length) {
      mostValues = middleTemperatures;
    }
    else if (maxLength === rightTemperatures.length) {
      mostValues = rightTemperatures;
    }
    else if (maxLength === REMTemperatures.length) {
      mostValues = REMTemperatures;
    }

    let combinedDataRows = [];

    for (let index in mostValues) {
      const value = mostValues[index];

      const time = new Date(value.time);

      let width;

      for (let i in widths) {
        width = widths[i];

        if (new Date(width.time) >= time) {
          break;
        }
      }

      let leftDeepness;

      for (let i in leftDeepnesses) {
        leftDeepness = leftDeepnesses[i];

        if (new Date(leftDeepness.time) >= time) {
          break;
        }
      }

      let rightDeepness;

      for (let i in rightDeepnesses) {
        rightDeepness = rightDeepnesses[i];

        if (new Date(rightDeepness.time) >= time) {
          break;
        }
      }

      let leftTemperature;

      for (let i in leftTemperatures) {
        leftTemperature = leftTemperatures[i];

        if (new Date(leftTemperature.time) >= time) {
          break;
        }
      }

      let middleTemperature;

      for (let i in middleTemperatures) {
        middleTemperature = middleTemperatures[i];

        if (new Date(middleTemperature.time) >= time) {
          break;
        }
      }

      let rightTemperature;

      for (let i in rightTemperatures) {
        rightTemperature = rightTemperatures[i];

        if (new Date(rightTemperature.time) >= time) {
          break;
        }
      }

      let REMTemperature;

      for (let i in REMTemperatures) {
        REMTemperature = REMTemperatures[i];

        if (new Date(REMTemperature.time) >= time) {
          break;
        }
      }

      const combinedData = {
        Päivämäärä: time.getDate() + '.' + (time.getMonth() + 1) + '.' + time.getFullYear(),
        Aikaleima: paddedNumber(time.getHours()) + ':' + paddedNumber(time.getMinutes()) + ':' + paddedNumber(time.getSeconds()),
        Latitude: value.latitude,
        Longitude: value.longitude,
        Tieosoite: value.roadData,
        Jyrsinsyv_oik: rightDeepness ? rightDeepness.deepness : '-',
        Jyrsinsyv_vas: leftDeepness ? leftDeepness.deepness : '-',
        L_al: REMTemperature ? REMTemperature.temperature : '-',
        L_oik: rightTemperature ? rightTemperature.temperature : '-',
        L_kes: middleTemperature ? middleTemperature.temperature : '-',
        L_vas: leftTemperature ? leftTemperature.temperature : '-',
        Leveys: width ? width.width : '-'
      };

      combinedDataRows.push(combinedData);
    }

    return combinedDataRows;
  }

  makeAllDataExcel () {
    this.setState({ loadingAllDataExcel: true }, () => {
      timer(0).then(() => {
        let date;

        if (this.props.timeRangeStart !== '') {
          const startDate = new Date(this.props.timeRangeStart);
          const endDate = new Date(this.props.timeRangeEnd);
          const startString = startDate.getDate() + '.' + (startDate.getMonth() + 1) + '.' + startDate.getFullYear();
          const endString = endDate.getDate() + '.' + (endDate.getMonth() + 1) + '.' + endDate.getFullYear();

          if (startString === endString) {
            date = startString;
          }
          else {
            date = startString + ' - ' + endString;
          }
        }

        this.allDataName = this.state.name + ' - sensori data - ' + (date || 'Koko ajalta');

        this.allData = this.getAllData();

        this.setState({ loadingAllDataExcel: false });
        this.toggleAllDataExcel();
      });
    });
  }

  getAllDataMeta () {
    const contract = this.props.contracts.find(contract => contract.get('id') === this.props.selectedContract);
    const site = this.props.selectedConstructionSite;

    let sensorData = [];

    let widths = [];

    this.state.widths.map(x => sensorData.filter(a =>
      a.roadData === x.roadData).length > 0 ? null : widths.push(x));

    let deepnesses = [];

    this.state.deepnesses.map(x => sensorData.filter(a =>
      a.roadData === x.roadData).length > 0 ? null : deepnesses.push(x));

    let temperatures = [];

    this.state.temperatures.map(x => sensorData.filter(a =>
      a.roadData === x.roadData).length > 0 ? null : temperatures.push(x));

    let angles = [];

    sensorData = sensorData.concat(widths, deepnesses, temperatures);

    if (sensorData.length === 0) {
      return;
    }

    let roadNumber;

    let minPart = Infinity;
    let minDistance = Infinity;
    let maxPart = 0;
    let maxDistance = 0;

    let minTime = new Date(8640000000000000);
    let maxTime = new Date(0);

    for (let value of sensorData) {
      if (value['road_number'] !== null) {
        roadNumber = value['road_number'];

        if (value['road_part'] > maxPart) {
          maxPart = value['road_part']
          maxDistance = value['road_distance']
        }

        if (value['road_part'] < minPart) {
          minPart = value['road_part']
          minDistance = value['road_distance']
        }

        if (value['road_part'] === maxPart && value['road_part'] === minPart) {
          if (value['road_distance'] > maxDistance) {
            maxDistance = value['road_distance']
          }
          else if (value['road_distance'] < minDistance) {
            minDistance = value['road_distance']
          }
        }
      }

      const time = new Date(value.time);

      if (time < minTime) {
        minTime = time;
      }
      else if (time > maxTime) {
        maxTime = time;
      }
    }

    let dataDate = minTime.getDate() + '.' + (minTime.getMonth() + 1) + '.' + minTime.getFullYear();

    if (minTime.getDate() !== maxTime.getDate() || minTime.getMonth() !== maxTime.getMonth()) {
      dataDate += ' - ' + maxTime.getDate() + '.' + (maxTime.getMonth() + 1) + '.' + maxTime.getFullYear()
    }

    const today = new Date();
    const reportDate = today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear();

    const combinedData = {
      orderer: contract.get('orderer') || '-',
      organization: this.props.organizationName,
      contract: contract.get('name'),
      site: site.get('name'),
      custom_id: site.get('custom_id'),
      distanceInterval: roadNumber + '/' + minPart + '/' + minDistance + ' - ' + roadNumber + '/' + maxPart + '/' + maxDistance,
      lane: site.get('lane') || '-',
      laneDetail: site.get('lane_detail') || '-',
      dataDate: dataDate,
      reportDate: reportDate,
      coordinateSystem: 'WGS84',
      operationType: site.get('operation_type') || '-',
      locationSensor: 'OEM 4G LTE/WIFI/GPS/GLONASS Combo Antenni',
      deepnessSensor: deepnesses.length === 0 ? '-' : 'IFM UGT512 Ultraäänianturi',
      angleSensor: angles.length === 0 ? '-' : '',
      temperatureSensor: temperatures.length === 0 ? '-' : 'IFM TW2000 Infrapunalämpötila-anturi',
      widthSensor: widths.length === 0 ? '-' : 'IFM UGT512 Ultraäänianturi'
    };

    return [combinedData];
  }

  makeAllDataMetaExcel () {
    this.setState({ loadingAllDataMetaExcel: true }, () => {
      timer(0).then(() => {
        this.allDataMetaName = this.state.name + '_meta';
        this.allDataMeta = this.getAllDataMeta();

        this.setState({ loadingAllDataMetaExcel: false });
        this.toggleAllDataMetaExcel();
      });
    });
  }

  setWidthCrosshairValue (value) {
    const width = this.state.widths.find(width => new Date(width.time).getTime() === value.x);

    if (width == null) {
      return;
    }

    const date = new Date(width['time']);
    const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
      + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

    this.setState({
      crosshairValue: [{
        x: value.x,
        time: time,
        roadPart: width['road_part'],
        roadDistance: width['road_distance'],
        width: width['width'],
      }]
    });
  }

  setDeepnessCrosshairValue (value) {
    const date = new Date(value.x);
    const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
      + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

    const leftDeepness = this.state.deepnessVisibleGraphData[0].reverse().find(data => data.x >= value.x);
    const rightDeepness = this.state.deepnessVisibleGraphData[1].reverse().find(data => data.x >= value.x);
    const deepness = this.state.deepnesses.find(deepness => new Date(deepness.time).getTime() === value.x);

    this.setState({
      crosshairValue: [{
        x: value.x,
        time: time,
        roadPart: deepness['road_part'],
        roadDistance: deepness['road_distance'],
        leftDeepness: leftDeepness ? leftDeepness.y : '-',
        rightDeepness: rightDeepness ? rightDeepness.y : '-',
      }]
    });
  }

  setTemperatureCrosshairValue (value) {
    const date = new Date(value.x);
    const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
      + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

    const left = this.state.temperatureVisibleGraphData[0].reverse().find(data => data.x >= value.x);
    const middle = this.state.temperatureVisibleGraphData[1].reverse().find(data => data.x >= value.x);
    const right = this.state.temperatureVisibleGraphData[2].reverse().find(data => data.x >= value.x);
    const REM = this.state.temperatureVisibleGraphData[3].reverse().find(data => data.x >= value.x);
    const temperature = this.state.temperatures.find(temperature => new Date(temperature.time).getTime() === value.x);

    this.setState({
      crosshairValue: [{
        x: value.x,
        time: time,
        roadPart: temperature['road_part'],
        roadDistance: temperature['road_distance'],
        leftTemperature: left ? left.y : '-',
        middleTemperature: middle ? middle.y : '-',
        rightTemperature: right ? right.y : '-',
        REMTemperature: REM ? REM.y : '-'
      }]
    });
  }

  resetCrosshairValue () {
    this.setState({ crosshairValue: [] });
  }

  showAmount (amount) {
    let widthGraphData = [[]];
    let deepnessGraphData = [[], []];
    let temperatureGraphData = [[], [], [], []];

    for (let i = 0; i < this.state.widthGraphData[0].length / amount; i++) {
      const data = this.state.widthGraphData[0][i * amount];
      widthGraphData[0].push({ x: data.x, y: data.y });
    }

    for (let g = 0; g < this.state.deepnessGraphData.length; g++) {
      for (let i = 0; i < this.state.deepnessGraphData[g].length / amount; i++) {
        const data = this.state.deepnessGraphData[g][i * amount];
        deepnessGraphData[g].push({ x: data.x, y: data.y });
      }
    }

    for (let g = 0; g < this.state.temperatureGraphData.length; g++) {
      for (let i = 0; i < this.state.temperatureGraphData[g].length / amount; i++) {
        const data = this.state.temperatureGraphData[g][i * amount];
        temperatureGraphData[g].push({ x: data.x, y: data.y });
      }
    }

    this.setState({
      dataFilterAmount: amount,
      widthVisibleGraphData: widthGraphData,
      deepnessVisibleGraphData: deepnessGraphData,
      temperatureVisibleGraphData: temperatureGraphData
    });
  }

  show (value, event) {
    let sensors = this.state.sensors.slice();

    if (event.target.checked) {
      sensors.push(value);
    }
    else {
      const index = sensors.findIndex(sensor => sensor === value);
      sensors.splice(index, 1);
    }

    this.setState({ sensors: sensors });
  }

  confirmDeleteSelectedData () {
    let text;

    if (this.state.showSensor === 0) {
      text = 'leveydet';
    }
    else if (this.state.showSensor === 1) {
      text = 'Syvyydet';
    }
    else {
      text = 'lämpötilat';
    }

    if (this.props.timeRangeStart !== '') {
      const startDate = new Date(this.props.timeRangeStart);
      const startTime = startDate.getDate() + '.' + (startDate.getMonth() + 1) +
        '.' + startDate.getFullYear() + ' ' + paddedNumber(startDate.getHours()) +
        ':' + paddedNumber(startDate.getMinutes());

      const endDate = new Date(this.props.timeRangeEnd);
      const endTime = endDate.getDate() + '.' + (endDate.getMonth() + 1) +
        '.' + endDate.getFullYear() + ' ' + paddedNumber(endDate.getHours()) +
        ':' + paddedNumber(endDate.getMinutes());

      text += ' ajalta ' + startTime + ' - ' + endTime;
    }

    this.props.showConfirm('Poistetaanko kaikki kohteen ' + text + '?', this.deleteSelectedData);
  }

  deleteSelectedData () {
    let url;

    const siteId = this.props.selectedConstructionSite.get('id');

    let widths = this.state.widths;
    let widthVisibleGraphData = this.state.widthVisibleGraphData;
    let widthGraphData = this.state.widthGraphData;
    let deepnesses = this.state.deepnesses;
    let deepnessVisibleGraphData = this.state.deepnessVisibleGraphData;
    let deepnessGraphData = this.state.deepnessGraphData;
    let temperatures = this.state.temperatures;
    let temperatureVisibleGraphData = this.state.temperatureVisibleGraphData;
    let temperatureGraphData = this.state.temperatureGraphData;

    if (this.state.showSensor === 0) {
      url = '/width/site/' + siteId;
      widths = [];
      widthVisibleGraphData = [];
      widthGraphData = [];
    }
    else if (this.state.showSensor === 1) {
      url = '/deepness/site/' + siteId;
      deepnesses = [];
      deepnessVisibleGraphData = [];
      deepnessGraphData = [];
    }
    else {
      url = '/temperature/site/' + siteId;
      temperatures = [];
      temperatureVisibleGraphData = [];
      temperatureGraphData = [];
    }

    const startTime = this.props.timeRangeStart;
    const endTime = this.props.timeRangeEnd;

    if (startTime !== '') {
      url += '?timestart=' + startTime + '&timeend=' + endTime;
    }

    fetch(url, 'DELETE').then(data => {
      this.props.showNotice('Poistettu', 'Ok');
      this.setState({
        widths: widths,
        widthVisibleGraphData: widthVisibleGraphData,
        widthGraphData: widthGraphData,
        deepnesses: deepnesses,
        deepnessVisibleGraphData: deepnessVisibleGraphData,
        deepnessGraphData: deepnessGraphData,
        temperatures: temperatures,
        temperatureVisibleGraphData: temperatureVisibleGraphData,
        temperatureGraphData: temperatureGraphData
      });
    }).catch(data => {
      this.props.showNotice('Poisto epäonnistui', 'Error');
    });
  }

  makeExcel (headers, dataHeaders, data) {
    let array = [];
    let headerLine = [];
    let wscols = [];

    let fields = [];

    let longestStringIndexes = [];

    for (let header of headers) {
      fields.push(header);
      headerLine.push(header);
    };

    array.push(headerLine);

    for (let object of data) {
      let line = [];
      let index = 0;

      for (let dataHeader of dataHeaders) {
        let column;

        if (dataHeader.includes('.')) {
          column = this.findData(dataHeader, object);
        }
        else {
          column = object[dataHeader];
        }

        line.push(column ? column : '-');

        const length = line[line.length - 1.].toString().length;

        if (longestStringIndexes[index] < length) {
          longestStringIndexes[index] = length;
        }

        index++;
      }

      array.push(line);
    }

    const worksheet = XLSX.utils.aoa_to_sheet(array);
    const new_workbook = XLSX.utils.book_new();

    worksheet['!cols'] = wscols;
    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'Raportti');
    return XLSX.write(new_workbook, { bookType: "csv", type: "array" })
  }

  getVaylaName () {
    let name = '';

    let sampleObject;

    if (this.state.widths.length !== 0) {
      sampleObject = this.state.widths[0];
    }
    else if (this.state.deepnesses.length !== 0) {
      sampleObject = this.state.deepnesses[0];
    }
    else if (this.state.temperatures.length !== 0) {
      sampleObject = this.state.temperatures[0];
    }

    const dataDate = new Date(sampleObject['time']);

    name += dataDate.getFullYear() + '/' + paddedNumber((dataDate.getMonth() + 1)) + '/' + paddedNumber(dataDate.getDate()) + '/'

    const timestamp = new Date();
    name += timestamp.getFullYear() + paddedNumber((timestamp.getMonth() + 1)) + paddedNumber(timestamp.getDate()) +
      '_' + paddedNumber(timestamp.getHours()) + paddedNumber(timestamp.getMinutes()) + paddedNumber(timestamp.getSeconds());

    name += '_' + this.props.selectedConstructionSite.get('operation_type');

    name += '_' + this.props.selectedConstructionSite.get('road_number');

    return name;
  }

  async postDataToS3 (file) {
    function getSignatureKey (key, dateStamp, regionName, serviceName) {
      var kDate = CryptoJS.HmacSHA256(dateStamp, "AWS4" + key);
      var kRegion = CryptoJS.HmacSHA256(regionName, kDate);
      var kService = CryptoJS.HmacSHA256(serviceName, kRegion);
      var kSigning = CryptoJS.HmacSHA256("aws4_request", kService);
      return kSigning;
    }

    let access_key;
    let secret_key;

    await fetch('/accesskey/s3').then(data => {
      access_key = data[0];
      secret_key = data[1];
    });

    let method = 'POST';
    let service = 's3';
    let region = 'eu-north-1';

    let now = new Date();
    let time = new Date(now.getTime() + 2 * 60000);
    let amz_date = time.getFullYear() + paddedNumber((time.getMonth() + 1)) + paddedNumber(time.getDate()) +
      'T' + paddedNumber(time.getHours()) + paddedNumber(time.getMinutes()) + paddedNumber(time.getSeconds()) + 'Z';

    let date_stamp = time.getFullYear() + paddedNumber(time.getMonth() + 1) + paddedNumber(time.getDate());

    let credential_scope = date_stamp + '/' + region + '/' + service + '/aws4_request';

    const credential = access_key + '/' + credential_scope;

    const policy = `{ "expiration": "` + time.toISOString() + `",
                      "conditions": [
                        {"acl": "public-read" },
                        {"bucket": "vayla-paallyste-data" },
                        {"x-amz-date": "` + amz_date + `" },
                        {"x-amz-server-side-encryption": "AES256"},
                        ["starts-with", "$key", ""],
                        {"x-amz-algorithm": "AWS4-HMAC-SHA256"},
                        {"x-amz-credential": "` + credential + `"},
                      ]
                    }`;

    const policyBase64 = window.btoa(policy);

    let signing_key = await getSignatureKey(secret_key, date_stamp, region, service);

    let signature = CryptoJS.HmacSHA256(policyBase64, signing_key).toString(CryptoJS.enc.Hex);

    const formData = new FormData();
    formData.append('key', file.name);
    formData.append('acl', 'public-read');

    formData.append('x-amz-server-side-encryption', 'AES256');
    formData.append('X-Amz-Credential', credential);
    formData.append('X-Amz-Algorithm', 'AWS4-HMAC-SHA256');
    formData.append('X-Amz-Date', amz_date);

    formData.append('Policy', policyBase64);
    formData.append('X-Amz-Signature', signature);

    formData.append('file', file);

    return window.fetch('https://' + config.cors + '/http://vayla-paallyste-data.s3.amazonaws.com/', {
      method: method,
      body: formData,
    }).then(response => {
      if (response.ok) {
        return true;
      }

      return false;
    }).catch(error => {
      console.log(error);
      return false;
    });
  }

  async sendDataToVayla () {
    this.setState({ loadingVayla: true });

    const data = this.getAllData();
    const CSV = this.makeExcel(this.allDataHeaders, this.allDataHeaders, data);
    const blob = new Blob([new Uint8Array(CSV)], { type: "text/csv" });
    const vaylaName = this.getVaylaName();
    const fileName = vaylaName + '.csv';
    const file = new File([blob], fileName)
    const success = await this.postDataToS3(file);

    const metaData = this.getAllDataMeta();
    const metaCSV = this.makeExcel(this.metaHeaders, this.metaDataHeaders, metaData);
    const metaBlob = new Blob([new Uint8Array(metaCSV)], { type: "text/csv" });
    const metaFileName = vaylaName + '_meta.csv';
    const metaFile = new File([metaBlob], metaFileName)
    const metasSuccess = await this.postDataToS3(metaFile);

    this.setState({ loadingVayla: false });

    if (success && metasSuccess) {
      this.props.showNotice('Kaikki data lähetetty', 'Ok');
    }
    else {
      this.props.showNotice('Virhe tapahtui lähetyksessä', 'Error');
    }
  }

  render () {
    const width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;

    const height = window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight;

    return (
      <div>
        {!this.state.loadingAllDataExcel ?
          <button className='button-primary'
            onClick={this.makeAllDataExcel}
            disabled={this.state.loadingTemperatures ||
              this.state.loadingDeepnesses ||
              this.state.loadingTemperatures ||
              this.state.name == null}>
            Lataa mittaustieto raportti
          </button>
          :
          <div className='main loader' />
        }

        {!this.state.loadingAllDataMetaExcel ?
          <button className='button-outline'
            onClick={this.makeAllDataMetaExcel}
            disabled={this.state.loadingTemperatures ||
              this.state.loadingDeepnesses ||
              this.state.loadingTemperatures ||
              this.state.name == null}>
            Lataa mittaustiedon metatieto
          </button>
          :
          <div className='main loader' />
        }

        <button id='vayla-button' className='button-primary'
          onClick={this.sendDataToVayla}
          disabled={this.state.loadingTemperatures ||
            this.state.loadingDeepnesses ||
            this.state.loadingTemperatures ||
            this.state.name == null ||
            this.state.loadingVayla}>
          Lähetä data väylälle
        </button>

        <div className='center'>
          <div className={"sensor-select" + (this.state.showSensor === 0 ? ' selected' : '')}
            onClick={this.state.showSensor === 0 ? null : this.showSensor.bind(null, 0)}>
            Leveys
          </div>
          <div className={"sensor-select" + (this.state.showSensor === 1 ? ' selected' : '')}
            onClick={this.state.showSensor === 1 ? null : this.showSensor.bind(null, 1)}>
            Syvyys
          </div>
          <div className={"sensor-select" + (this.state.showSensor === 2 ? ' selected' : '')}
            onClick={this.state.showSensor === 2 ? null : this.showSensor.bind(null, 2)}>
            Lämpötila
          </div>
        </div>
        <button onClick={this.toggleMakeExcel}
          disabled={this.state.loading || this.state.name == null}>
          Luo raportti
        </button>
        <button className='float-right red-text button-outline'
          onClick={this.confirmDeleteSelectedData}
          disabled={this.state.loading || this.state.name == null}>
          Poista näkyvät
        </button>
        <br />
        {this.state.loading ? <div className='loader' /> :
          <div className='center'>
            {'Näytä: '}
            <label>
              1:1
              <input type='radio' name='amount' value='1'
                onChange={this.showAmount.bind(this, 1)}
                defaultChecked={this.state.dataFilterAmount === 1} />
            </label>
            <label>
              1:10
              <input type='radio' name='amount' value='10'
                onChange={this.showAmount.bind(this, 10)}
                defaultChecked={this.state.dataFilterAmount === 10} />
            </label>
            <label>
              1:50
              <input type='radio' name='amount' value='50'
                onChange={this.showAmount.bind(this, 50)}
                defaultChecked={this.state.dataFilterAmount === 50} />
            </label>
            <label>
              1:100
              <input type='radio' name='amount' value='100'
                onChange={this.showAmount.bind(this, 100)}
                defaultChecked={this.state.dataFilterAmount === 100} />
            </label>
            {this.state.showSensor === 0 ?
              <WidthSensor widths={this.state.widths} selectMass={this.props.selectMass}
                name={this.state.name} makeExcel={this.state.makeExcel}
                toggleMakeExcel={this.toggleMakeExcel} graphData={this.state.widthVisibleGraphData}
                crosshairValue={this.state.crosshairValue}
                setCrosshairValue={this.setWidthCrosshairValue}
                resetCrosshairValue={this.resetCrosshairValue}
                showAmount={this.showAmount}
                dataFilterAmount={this.state.dataFilterAmount}
                width={width} height={height}
                loading={this.state.loadingWidth} />
              : null}
            {this.state.showSensor === 1 ?
              <DeepnessSensor deepnesses={this.state.deepnesses} selectMass={this.props.selectMass}
                name={this.state.name} makeExcel={this.state.makeExcel}
                toggleMakeExcel={this.toggleMakeExcel} graphData={this.state.deepnessVisibleGraphData}
                crosshairValue={this.state.crosshairValue}
                setCrosshairValue={this.setDeepnessCrosshairValue}
                resetCrosshairValue={this.resetCrosshairValue}
                showAmount={this.showAmount}
                dataFilterAmount={this.state.dataFilterAmount}
                width={width} height={height}
                show={this.show} sensors={this.state.sensors}
                loading={this.state.loadingDeepnesses} />
              : null}
            {this.state.showSensor === 2 ?
              <TemperatureSensor temperatures={this.state.temperatures} selectMass={this.props.selectMass}
                name={this.state.name} makeExcel={this.state.makeExcel}
                toggleMakeExcel={this.toggleMakeExcel} graphData={this.state.temperatureVisibleGraphData}
                crosshairValue={this.state.crosshairValue}
                setCrosshairValue={this.setTemperatureCrosshairValue}
                resetCrosshairValue={this.resetCrosshairValue}
                showAmount={this.showAmount}
                dataFilterAmount={this.state.dataFilterAmount}
                width={width} height={height}
                show={this.show} sensors={this.state.sensors}
                loading={this.state.loadingTemperatures} />
              : null}
          </div>
        }
        <Excel show={this.state.allDataExcel} toggle={this.toggleAllDataExcel}
          name={this.allDataName}
          headers={this.allDataHeaders}
          dataHeaders={this.allDataHeaders}
          data={fromJS(this.allData)}
          fileType={'csv'} />
        <Excel show={this.state.allDataMetaExcel} toggle={this.toggleAllDataMetaExcel}
          name={this.allDataMetaName}
          headers={this.metaHeaders}
          dataHeaders={this.metaDataHeaders}
          data={fromJS(this.allDataMeta)}
          fileType={'csv'} />
      </div>
    );
  }
}

export default connect(state => ({
  selectedContract: state.contractSelect.get('selectedContract'),
  contracts: state.contractSelect.get('contracts'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  timeRangeStart: state.timeRange.get('startTime'),
  timeRangeEnd: state.timeRange.get('endTime'),
  masses: state.mass.get('masses'),
  organizationId: state.login.get('user') ? state.login.get('user').get('organizationId') : null,
  organizationName: state.login.get('user') ? state.login.get('user').get('organizationName') : null,
}), { showNotice, showConfirm })(Sensors);
