import config from '../settings/config';

const API_URL = 'https://' + config.cors + '/https://' + config.api;
const WS_URL = 'wss://' + config.socket;
export const ROAD_URL = 'https://' + config.road;
export const ROAD_URL2 = 'https://' + config.road2;


export const paddedNumber = number => number <= 99 ? ('0' + number).slice(-2) : number;


export function Socket (url) {
  const socket = new WebSocket(WS_URL + url + '?x-nevia-auth=Bearer' + localStorage['login'].substring(7));
  socket.onopen = function (e) {
    console.log("SOCKET CONNECTED");
  }
  return socket;
}

export class WebWorker {
  constructor(worker) {
    const code = worker.toString();
    const blob = new Blob(["(" + code + ")()"]);
    return new Worker(URL.createObjectURL(blob));
  }
}

export const timer = (time, data) => (
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, time);
  })
);

export const transformErrors = errors => {
  return errors.map(error => {
    if (error.schema.messages && error.schema.messages[error.name][error.argument]) {
      return {
        ...error,
        message: error.schema.messages[error.name][error.argument],
      };
    }
    return error;
  });
};

export const getRoadData = async (y, x, accuracy = 10, road = null) => {
  let data;

  let url = '?y=' + y + '&x=' + x + '&sade=' + accuracy;

  if (road != null) {
    url += '&tie=' + road
  }

  try {
    const fetchURL = ROAD_URL + url + '&ajorata=0,1,2';

    data = await (await window.fetch(fetchURL)).json();

    const roadData = data.features[0].properties;

    if (roadData.virheet != null || roadData.tie == null) {
      throw Error(roadData.virheet);
    }

    return {
      road: roadData.tie,
      part: roadData.osa,
      distance: roadData.etaisyys,
      y: roadData.y,
      x: roadData.x,
      address: roadData.katunimi ? roadData.katunimi + (roadData.katunumero ? (' ' + roadData.katunumero) : '') : null,
      city: roadData.kuntanimi
    }
  } catch (error) {
    try {
      const fetchURL = ROAD_URL2 + '/muunna' + url;

      data = await (await window.fetch(fetchURL)).json();

      if (data.virhe != null) {
        return null;
      }

      return {
        road: data.tie,
        part: data.osa,
        distance: data.etaisyys,
        y: data.y,
        x: data.x
      };
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}

export const getRoadDataFromCoordinates = async (coordinates, accuracy = 10, road = null) => {
  let data = [];

  const MAX_DATA_AMOUNT_PER_QUERY = 1000;
  let ready = false;

  let currentIndex = 0;

  while (!ready) {
    let lastIndex = currentIndex + MAX_DATA_AMOUNT_PER_QUERY;

    if (coordinates.length < lastIndex) {
      lastIndex = coordinates.length;
    }

    const coordinatesPart = coordinates.slice(currentIndex, currentIndex + MAX_DATA_AMOUNT_PER_QUERY);

    if (coordinatesPart.length === 0) {
      break;
    }

    currentIndex = lastIndex;

    let json = '[';

    for (let coordinate of coordinatesPart) {
      json += '{y:"' + coordinate.y + '",x:"' + coordinate.x + '",sade:' + accuracy + ',ajorata:"0,1,2"';

      if (road != null) {
        json += ',tie:' + road;
      }

      json += '},';
    }

    json = json.slice(0, -1);
    json += ']';

    try {
      let formData = new FormData();
      formData.append('json', json);

      const response = await (await window.fetch(ROAD_URL, {
        method: 'POST',
        body: formData,
        mode: 'cors',
      })).json();

      for (let feature of response.features) {
        const roadData = feature.properties;

        if (roadData.virheet != null || roadData.tie == null) {
          data.push({
            road: null,
            part: null,
            distance: null
          });

          continue;
        }

        data.push({
          road: roadData.tie,
          part: roadData.osa,
          distance: roadData.etaisyys,
          y: roadData.y,
          x: roadData.x,
          address: roadData.katunimi ? roadData.katunimi + (roadData.katunumero ? (' ' + roadData.katunumero) : '') : null,
          city: roadData.kuntanimi
        });
      }
    } catch (error) {
      console.log(error)
      return null;
    }

    if (coordinates.length === lastIndex) {
      ready = true
    }
  }

  return data;
}

export const getRoadCoordinates = async (road, part, distance) => {
  let data;

  let url = '?tie=' + road + '&osa=' + part + '&etaisyys=' + distance;

  try {
    const fetchURL = ROAD_URL + url;

    data = await (await window.fetch(fetchURL)).json();

    const roadData = data.features[0].properties;

    if (roadData.virheet != null) {
      throw Error(roadData.virheet);
    }

    return {
      y: roadData.y,
      x: roadData.x,
      address: roadData.katunimi ? roadData.katunimi + (roadData.katunumero ? (' ' + roadData.katunumero) : '') : null,
      city: roadData.kuntanimi
    }
  } catch (error) {
    try {
      const fetchURL = ROAD_URL2 + '/muunna' + url;

      data = await (await window.fetch(fetchURL)).json();

      if (data.virhe != null) {
        return false;
      }

      const point = data['alkupiste']['tieosoitteet'][0]['point'];

      return { y: point.y, x: point.x }
    } catch (error) {
      console.log(error);
    }
  }

  return null;
}

export function fetch (url, method = 'GET', data = null) {
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'x-nevia-auth': localStorage['login']
  };
  if (data != null) {
    data = JSON.stringify(data);
  }
  return window.fetch(API_URL + url, {
    method: method,
    headers: headers,
    body: data,
    mode: 'cors',
  }).then(request => {
    if (!request.ok) {
      throw Error(request.status);
    }

    if (request.status !== 204) return request.json();

    return null;
  }).catch(error => {
    console.log(error);
  });
}

export function fetchSensorData (start, end, device, positions = false) {
  const url = 'https://eu-central-1-1.aws.cloud2.influxdata.com/api/v2/query?org=henry.palonen@palonenlabs.com';

  let headers = {
    'Accept': 'application/csv',
    'Content-Type': 'application/vnd.flux',
    'Authorization': 'Token HClMmXzjNNno_6-LuOv8hCjBE-txQaPC8nglq9UCzSPhlMmTbq9doPZ6LFh-hBBwx4S3ZeXkxYjct-FXOj1a4Q=='
  };

  return window.fetch(url, {
    headers: headers,
    method: 'POST',
    body: 'from(bucket:"Nevia")\n|> range(start:' + start + ', stop: ' + end + ')\n' +
      (!positions ?
        '|> filter(fn: (r) => r["_measurement"] == "ethernet")\n|> filter(fn: (r) => r["_field"] == "data" or r["_field"] == "value")'
        :
        '|> filter(fn: (r) => r["_measurement"] == "position")')
      + '\n|> sort(columns: ["_time"], desc: false)'
      + (device != null ? '\n|> filter(fn: (r) => r["device"] == "' + device + '")' : '\n|> group(columns: ["device"])\n|> last()\n|> yield(name: "last")')
    ,
    mode: 'cors',
  }).then(request => {
    if (!request.ok) {
      throw Error(request.status);
    }

    if (request.status !== 204) return request.blob();

    return null;
  });
}

export function integerValue (value, defaultValue) {
  const parsedValue = parseInt(value, 10);

  if (isNaN(parsedValue)) {
    return defaultValue;
  }

  return parsedValue;
}

export function floatValue (value, defaultValue) {
  try {
    const parsedValue = parseFloat(value.replace(',', '.'));

    if (isNaN(parsedValue)) return defaultValue;

    return parsedValue;
  } catch (err) {
    return defaultValue;
  }
}

export function median (values) {
  if (values.length === 0) return 0;

  values.sort(function (a, b) {
    return a - b;
  });

  const half = Math.floor(values.length / 2);

  if (values.length % 2) return values[half];

  return (values[half - 1] + values[half]) / 2.0;
}

export function mode (values) {
  return Object.values(
    values.reduce((count, e) => {
      if (!(e in count)) {
        count[e] = [0, e];
      }

      count[e][0]++;
      return count;
    }, {})
  ).reduce((a, v) => v[0] < a[0] ? a : v, [0, null])[1];
}

export function toDegrees (angle) {
  return angle * (180 / Math.PI);
}

export function toRadians (angle) {
  return angle * (Math.PI / 180);
}

const Ca = 6378137.0;
const Cf = 1.0 / 298.257223563;
const Ck0 = 0.9996;
const Clo0 = toRadians(27.0);
const CE0 = 500000.0;
const Cn = Cf / (2.0 - Cf);
const CA1 = Ca / (1.0 + Cn) * (1.0 + (Math.pow(Cn, 2.0)) / 4.0 + (Math.pow(Cn, 4.0)) / 64.0);
const Ce = Math.sqrt((2.0 * Cf - Math.pow(Cf, 2.0)));
const Ch1p = 1.0 / 2.0 * Cn - 2.0 / 3.0 * Math.pow(Cn, 2.0) + 5.0 / 16.0 * Math.pow(Cn, 3.0) + 41.0 / 180.0 * Math.pow(Cn, 4.0);
const Ch2p = 13.0 / 48.0 * Math.pow(Cn, 2.0) - 3.0 / 5.0 * Math.pow(Cn, 3.0) + 557.0 / 1440.0 * Math.pow(Cn, 4.0);
const Ch3p = 61.0 / 240.0 * Math.pow(Cn, 3.0) - 103.0 / 140.0 * Math.pow(Cn, 4.0);
const Ch4p = 49561.0 / 161280.0 * Math.pow(Cn, 4.0);
const Ch1 = 1.0 / 2.0 * Cn - 2.0 / 3.0 * (Math.pow(Cn, 2.0)) + 37.0 / 96.0 * (Math.pow(Cn, 3.0)) - 1.0 / 360.0 * (Math.pow(Cn, 4.0));
const Ch2 = 1.0 / 48.0 * (Math.pow(Cn, 2.0)) + 1.0 / 15.0 * (Math.pow(Cn, 3.0)) - 437.0 / 1440.0 * (Math.pow(Cn, 4.0));
const Ch3 = 17.0 / 480.0 * (Math.pow(Cn, 3.0)) - 37.0 / 840.0 * (Math.pow(Cn, 4.0));
const Ch4 = 4397.0 / 161280.0 * (Math.pow(Cn, 4.0));
const tm35fin_latitude_min = 6582464.0358;
const tm35fin_latitude_max = 7799839.8902;
const tm35fin_longitude_min = 50199.4814;
const tm35fin_longitude_max = 761274.6247;

export function toWGS84 (y, x) {
  if (y < tm35fin_latitude_min || y > tm35fin_latitude_max ||
    x < tm35fin_longitude_min || x > tm35fin_longitude_max) {
    return { latitude: 0, longitude: 0 };
  }

  const E = y / (CA1 * Ck0);
  const nn = (x - CE0) / (CA1 * Ck0);
  const E1p = Ch1 * Math.sin(2.0 * E) * Math.cosh(2.0 * nn);
  const E2p = Ch2 * Math.sin(4.0 * E) * Math.cosh(4.0 * nn);
  const E3p = Ch2 * Math.sin(6.0 * E) * Math.cosh(6.0 * nn);
  const E4p = Ch3 * Math.sin(8.0 * E) * Math.cosh(8.0 * nn);

  const nn1p = Ch1 * Math.cos(2.0 * E) * Math.sinh(2.0 * nn);
  const nn2p = Ch2 * Math.cos(4.0 * E) * Math.sinh(4.0 * nn);
  const nn3p = Ch3 * Math.cos(6.0 * E) * Math.sinh(6.0 * nn);
  const nn4p = Ch4 * Math.cos(8.0 * E) * Math.sinh(8.0 * nn);

  const Ep = E - E1p - E2p - E3p - E4p;

  const nnp = nn - nn1p - nn2p - nn3p - nn4p;
  const be = Math.asin(Math.sin(Ep) / Math.cosh(nnp));

  const Q = Math.asinh(Math.tan(be));
  let Qp = Q + Ce * Math.atanh(Ce * Math.tanh(Q));
  Qp = Q + Ce * Math.atanh(Ce * Math.tanh(Qp));
  Qp = Q + Ce * Math.atanh(Ce * Math.tanh(Qp));
  Qp = Q + Ce * Math.atanh(Ce * Math.tanh(Qp));

  const latitude = toDegrees(Math.atan(Math.sinh(Qp)));
  const longitude = toDegrees(Clo0 + Math.asin(Math.tanh(nnp) / Math.cos(be)));

  return { latitude: latitude, longitude: longitude };
}

export function toETRSTM35FIN (latitude, longitude) {
  const la = toRadians(latitude);
  const lo = toRadians(longitude);
  const Q = Math.asinh(Math.tan(la)) - Ce * Math.atanh(Ce * Math.sin(la));
  const be = Math.atan(Math.sinh(Q));
  const nnp = Math.atanh(Math.cos(be) * Math.sin(lo - Clo0));
  const Ep = Math.asin(Math.sin(be) * Math.cosh(nnp));
  const E1 = Ch1p * Math.sin(2.0 * Ep) * Math.cosh(2.0 * nnp);
  const E2 = Ch2p * Math.sin(4.0 * Ep) * Math.cosh(4.0 * nnp);
  const E3 = Ch3p * Math.sin(6.0 * Ep) * Math.cosh(6.0 * nnp);
  const E4 = Ch4p * Math.sin(8.0 * Ep) * Math.cosh(8.0 * nnp);
  const nn1 = Ch1p * Math.cos(2.0 * Ep) * Math.sinh(2.0 * nnp);
  const nn2 = Ch2p * Math.cos(4.0 * Ep) * Math.sinh(4.0 * nnp);
  const nn3 = Ch3p * Math.cos(6.0 * Ep) * Math.sinh(6.0 * nnp);
  const nn4 = Ch4p * Math.cos(8.0 * Ep) * Math.sinh(8.0 * nnp);
  const E = Ep + E1 + E2 + E3 + E4;
  const nn = nnp + nn1 + nn2 + nn3 + nn4;
  const y = CA1 * E * Ck0;
  const x = CA1 * nn * Ck0 + CE0;

  if (y < tm35fin_latitude_min || y > tm35fin_latitude_max ||
    x < tm35fin_longitude_min || x > tm35fin_longitude_max) {
    return { x: 0, y: 0 };
  }

  return { x: x, y: y };
}

export async function testIsValidRoadInfo (number, part, distance) {
  const url = ROAD_URL + '/muunna?tie=' + number +
    '&osa=' + part + '&etaisyys=' + distance;
  const data = await (await window.fetch(url)).json();
  if (data.virhe != null) return false;
  return true;
}

export function stateValueParser (event, type, defaultValue) {
  let value;

  if (type === 'integer') {
    value = integerValue(event.target.value, defaultValue);
  }
  else if (type === 'float') {
    value = event.target.value.replace(',', '.');
    const regExp = /^-?\d*\.?\d*$/;

    if (!regExp.test(value)) {
      return null;
    }

    if (value.length === 2 && value[0] === '0') {
      if (value[1] !== '.') {
        value = value[1];
      }
    }
  }
  else if (type === 'boolean') {
    value = event.target.checked;
  }
  else {
    value = event.target.value;
  }

  return value;
}
