export default () => {
    const mean = (values) => {
        let sum = 0;

        for (let i in values) {
            sum += parseInt(values[i]['_value'], 10);
        }

        return Math.round(sum / values.length);
    }

    const getLocation = (value, latitudes, longitudes, lastIndex) => {
        if (latitudes.length === 0 || longitudes.length === 0) {
            return {position: {latitude: 0, longitude: 0}, index: lastIndex};
        }
      
        const valueTime = new Date(value['_time']);
        
        for (let i = lastIndex; i < latitudes.length; i++) {
            const latitude = latitudes[i];
            const latitudeTime = new Date(latitude['_time']);

            if (valueTime < latitudeTime) {
                if (i === 0) {
                    return {position: {latitude: 0, longitude: 0}, index: lastIndex};
                }
                const latitude = parseFloat(latitudes[i - 1]['_value']);
                const longitude = parseFloat(longitudes[i - 1]['_value']);
                return {position: {latitude: latitude, longitude: longitude}, index: i};
            }
        }

        return {position: {latitude: 0, longitude: 0}, index: lastIndex};
    }

    /* eslint-disable-next-line no-restricted-globals */
    self.addEventListener("message", e => {
        if (!e) { return; }

        const sensorValues = e.data[0];

        if (!sensorValues) { return; }

        let widthWireValues = sensorValues.widthWireValues;
        let widthLeftValues = sensorValues.widthLeftValues;
        let widthRightValues = sensorValues.widthRightValues;
        let deepnessLeftBackValues = sensorValues.deepnessLeftBackValues;
        let deepnessLeftFrontValues = sensorValues.deepnessLeftFrontValues;
        let deepnessRightBackValues = sensorValues.deepnessRightBackValues;
        let deepnessRightFrontValues = sensorValues.deepnessRightFrontValues;
        let temperatureLeftValues = sensorValues.temperatureLeftValues;
        let temperatureMiddleValues = sensorValues.temperatureMiddleValues;
        let temperatureRightValues = sensorValues.temperatureRightValues;
        let temperatureREMValues = sensorValues.temperatureREMValues;

        let latitudes = e.data[1];
        let longitudes = e.data[2];
        let maxLength = e.data[3];
        const interval = e.data[4];

        let mostValues;

        if (maxLength === widthWireValues.length) {
            mostValues = widthWireValues;
        }
        else if (maxLength === widthLeftValues.length) {
            mostValues = widthLeftValues;
        }
        else if (maxLength === widthRightValues.length) {
            mostValues = widthRightValues;
        }
        else if (maxLength === deepnessLeftFrontValues.length) {
            mostValues = deepnessLeftFrontValues;
        }
        else if (maxLength === deepnessRightFrontValues.length) {
            mostValues = deepnessRightFrontValues;
        }
        else if (maxLength === deepnessLeftBackValues.length) {
            mostValues = deepnessLeftBackValues;
        }
        else if (maxLength === deepnessRightBackValues.length) {
            mostValues = deepnessRightBackValues;
        }
        else if (maxLength === temperatureLeftValues.length) {
            mostValues = temperatureLeftValues;
        }
        else if (maxLength === temperatureMiddleValues.length) {
            mostValues = temperatureMiddleValues;
        }
        else if (maxLength === temperatureRightValues.length) {
            mostValues = temperatureRightValues;
        }
        else if (maxLength === temperatureREMValues.length) {
            mostValues = temperatureREMValues;
        }

        let sensorValueLoadingCount = 0;

        let widthWireLocationIndex = 0;
        let widthLeftLocationIndex = 0;
        let widthRightLocationIndex = 0;
        let deepnessLeftFrontLocationIndex = 0;
        let deepnessRightFrontLocationIndex = 0;
        let deepnessLeftBackLocationIndex = 0;
        let deepnessRightBackLocationIndex = 0;
        let temperatureLeftLocationIndex = 0;
        let temperatureMiddleLocationIndex = 0;
        let temperatureRightLocationIndex = 0;
        let temperatureREMLocationIndex = 0;

        let newWidthWireValues = [];
        let newWidthLeftValues = [];
        let newWidthRightValues = [];
        let newDeepnessLeftBackValues = [];
        let newDeepnessLeftFrontValues = [];
        let newDeepnessRightBackValues = [];
        let newDeepnessRightFrontValues = [];
        let newTemperatureLeftValues = [];
        let newTemperatureMiddleValues = [];
        let newTemperatureRightValues = [];
        let newTemperatureREMValues = [];

        for (let index = 0; index < mostValues.length; index += interval) {
            const end = index + interval;

            if (end <= widthWireValues.length) {
                const array = widthWireValues.slice(index, end);
                let value = array[array.length - 1];
                value['_value'] = mean(array);

                const location = getLocation(value, latitudes, longitudes, widthWireLocationIndex);
                value.location = location.position;
                widthWireLocationIndex = location.index;

                newWidthWireValues.push(value);
            }

            if (end <= widthLeftValues.length) {
                const array = widthLeftValues.slice(index, end);
                let value = array[array.length - 1];
                value['_value'] = mean(array);

                const location = getLocation(value, latitudes, longitudes, widthLeftLocationIndex);
                value.location = location.position;
                widthLeftLocationIndex = location.index;

                newWidthLeftValues.push(value);
            }
            
            if (end <= widthRightValues.length) {
                const array = widthRightValues.slice(index, end);
                let value = array[array.length - 1];
                value['_value'] = mean(array);

                const location = getLocation(value, latitudes, longitudes, widthRightLocationIndex);
                value.location = location.position;
                widthRightLocationIndex = location.index;

                newWidthRightValues.push(value);
            }

            if (end <= deepnessLeftBackValues.length) {
                const array = deepnessLeftBackValues.slice(index, end);
                let value = array[array.length - 1];
                value['_value'] = mean(array);

                const location = getLocation(value, latitudes, longitudes, deepnessLeftBackLocationIndex);
                value.location = location.position;
                deepnessLeftBackLocationIndex = location.index;

                newDeepnessLeftBackValues.push(value);
            }

            if (end <= deepnessLeftFrontValues.length) {
                const array = deepnessLeftFrontValues.slice(index, end);
                let value = array[array.length - 1];
                value['_value'] = mean(array);

                const location = getLocation(value, latitudes, longitudes, deepnessLeftFrontLocationIndex);
                value.location = location.position;
                deepnessLeftFrontLocationIndex = location.index;

                newDeepnessLeftFrontValues.push(value);
            }

            if (end <= deepnessRightBackValues.length) {
                const array = deepnessRightBackValues.slice(index, end);
                let value = array[array.length - 1];
                value['_value'] = mean(array);

                const location = getLocation(value, latitudes, longitudes, deepnessRightBackLocationIndex);
                value.location = location.position;
                deepnessRightBackLocationIndex = location.index;

                newDeepnessRightBackValues.push(value);
            }

            if (end <= deepnessRightFrontValues.length) {
                const array = deepnessRightFrontValues.slice(index, end);
                let value = array[array.length - 1];
                value['_value'] = mean(array);

                const location = getLocation(value, latitudes, longitudes, deepnessRightFrontLocationIndex);
                value.location = location.position;
                deepnessRightFrontLocationIndex = location.index;

                newDeepnessRightFrontValues.push(value);
            }

            if (end <= temperatureLeftValues.length) {
                const array = temperatureLeftValues.slice(index, end);
                let value = array[array.length - 1];
                value['_value'] = mean(array) / 10;

                const location = getLocation(value, latitudes, longitudes, temperatureLeftLocationIndex);
                value.location = location.position;
                temperatureLeftLocationIndex = location.index;

                newTemperatureLeftValues.push(value);
            }

            if (end <= temperatureMiddleValues.length) {
                const array = temperatureMiddleValues.slice(index, end);
                let value = array[array.length - 1];
                value['_value'] = mean(array) / 10;

                const location = getLocation(value, latitudes, longitudes, temperatureMiddleLocationIndex);
                value.location = location.position;
                temperatureMiddleLocationIndex = location.index;

                newTemperatureMiddleValues.push(value);
            }

            if (end <= temperatureRightValues.length) {
                const array = temperatureRightValues.slice(index, end);
                let value = array[array.length - 1];
                value['_value'] = mean(array) / 10;

                const location = getLocation(value, latitudes, longitudes, temperatureRightLocationIndex);
                value.location = location.position;
                temperatureRightLocationIndex = location.index;

                newTemperatureRightValues.push(value);
                
            }

            if (end <= temperatureREMValues.length) {
                const array = temperatureREMValues.slice(index, end);
                let value = array[array.length - 1];
                value['_value'] = mean(array) / 10;

                const location = getLocation(value, latitudes, longitudes, temperatureREMLocationIndex);
                value.location = location.position;
                temperatureREMLocationIndex = location.index;

                newTemperatureREMValues.push(value);
            }

            sensorValueLoadingCount += interval;

            if (sensorValueLoadingCount > maxLength) {
                sensorValueLoadingCount = maxLength;
            }

            postMessage(sensorValueLoadingCount);
        }

        postMessage({
            widthWireValues: newWidthWireValues,
            widthLeftValues: newWidthLeftValues,
            widthRightValues: newWidthRightValues,
            deepnessLeftBackValues: newDeepnessLeftBackValues,
            deepnessLeftFrontValues: newDeepnessLeftFrontValues,
            deepnessRightBackValues: newDeepnessRightBackValues,
            deepnessRightFrontValues: newDeepnessRightFrontValues,
            temperatureLeftValues: newTemperatureLeftValues,
            temperatureMiddleValues: newTemperatureMiddleValues ,
            temperatureRightValues: newTemperatureRightValues,
            temperatureREMValues: newTemperatureREMValues,
        });
    })
};
