import React from 'react';
import { connect } from 'react-redux';
import { XYPlot, YAxis, LineSeries, Crosshair, DiscreteColorLegend } from 'react-vis';
import Papa from 'papaparse';
import { showMessage } from '../../../../src/message/Actions';
import { showConfirm } from '../../../../src/confirm/Actions';
import { showNotice } from '../../../../src/notice/Actions';
import ContractSelect from '../../../../src/contractSelect/ContractSelect';
import ConstructionSiteSelect from '../../../../src/constructionSiteSelect/ConstructionSiteSelect'                
import { fetchSensorData, paddedNumber, stateValueParser, timer, WebWorker, mode,
         fetch, toETRSTM35FIN, getRoadDataFromCoordinates, integerValue } from '../utils';
import worker from "./SensorDataWorker.js";
import senderWorker from "./SensorDataSenderWorker.js";
import './Sensor.css';
import TimeRange from '../../../../src/timeRange/TimeRange'

const CombineLoader = props => {
  if (!props.show) return null;

  return (
    <div className='modal'>
      <div id='combine-loader'>
        <h4>Liitetään dataa...</h4>
        <h4>{props.sensorValueLoadingCount + ' / ' + props.sensorValueCount}</h4>
        <h5>Älä sulje selainta</h5>
        <div className='loader' />
      </div>
    </div>
  );
};

const WidthGraph = props => {
  if (props.graphData.length === 0) {
    return null;
  }

  const crosshair = props.crosshairValue[0] != null ?
    <div id='crosshair-data'>
      <span>{props.crosshairValue[0].time}</span>
      <br/>
      <span>Leveys (m)</span>
      <br/>
      <span>{props.crosshairValue[0].width}</span>
    </div>
    : null;

    const ITEMS = [
      {title: 'Leveys', color: "blue", strokeDasharray: "dashed"},
    ];

  return (
    <div className='center'>
      <h4>Leveys</h4>
      <DiscreteColorLegend orientation="horizontal" width={300} items={ITEMS} />
      <XYPlot height={props.height * 0.2} width={props.width * 0.5} onMouseLeave={props.resetCrosshairValue}
              yPadding={20} xType="time">
        <YAxis />
          <LineSeries color="blue" data={props.graphData}
                      onNearestX={props.setCrosshairValue} />
        <Crosshair values={props.crosshairValue}>
          {crosshair}
        </Crosshair>
      </XYPlot>
    </div>
  );
}

const DeepnessGraph = props => {
  if (props.graphData[0].length === 0 &&
      props.graphData[1].length === 0) {
    return null;
  }

  const crosshair = props.crosshairValue[0] != null ?
    <div id='crosshair-data'>
      <span>{props.crosshairValue[0].time}</span>
      <br/>
      <span>syvyys (mm)</span>
      <br/>
      <span>Vasen: {props.crosshairValue[0].leftDeepness}</span>
      <br/>
      <span>Oikea: {props.crosshairValue[0].rightDeepness}</span>
      <br/>
      <span>Latitude: {props.crosshairValue[0].latitude}</span>
      <br/>
      <span>Longitude: {props.crosshairValue[0].longitude}</span>
    </div>
    : null;

  const ITEMS = [
    {title: 'Vasen', color: "blue", strokeDasharray: "dashed"},
    {title: 'Oikea', color: 'green', strokeDasharray: "dashed"},
  ];

  return (
    <div className='center'>
      <h4>Syvyys</h4>
      <DiscreteColorLegend orientation="horizontal" width={300} items={ITEMS} />
      <XYPlot height={props.height * 0.2} width={props.width * 0.5} onMouseLeave={props.resetCrosshairValue}
              yPadding={20} xType="time">
        <YAxis />
          <LineSeries color="blue" data={props.graphData[0]}
                      onNearestX={props.setCrosshairValue} />
          <LineSeries color="green" data={props.graphData[1]}
                      onNearestX={props.setCrosshairValue} />
        <Crosshair values={props.crosshairValue}>
          {crosshair}
        </Crosshair>
      </XYPlot>
    </div>
  );
}


const TemperatureGraph = props => {
  if (props.graphData[0].length === 0 &&
      props.graphData[1].length === 0 &&
      props.graphData[2].length === 0 &&
      props.graphData[3].length === 0) {
    return null;
  }

  const crosshair = props.crosshairValue[0] != null ?
    <div id='crosshair-data'>
      <span>{props.crosshairValue[0].time}</span>
      <br/>
      <span>{props.crosshairValue[0].roadPart} / {props.crosshairValue[0].roadDistance}</span>
      <br/>
      <span>Vasen: {Math.round(props.crosshairValue[0].leftTemperature)} C°</span>
      <br/>
      <span>Keski:{Math.round(props.crosshairValue[0].middleTemperature)} C°</span>
      <br/>
      <span>Oikea: {Math.round(props.crosshairValue[0].rightTemperature)} C°</span>
      <br/>
      <span>REM: {Math.round(props.crosshairValue[0].REMTemperature)} C°</span>
    </div>
    : null;

  const ITEMS = [
    {title: 'Vasen', color: "blue", strokeDasharray: "dashed"},
    {title: 'Keski', color: 'green', strokeDasharray: "dashed"},
    {title: 'Oikea', color: 'purple', strokeDasharray: "dashed"},
    {title: 'REM', color: 'red', strokeDasharray: "dashed"},
  ];

  return (
    <div className='center'>
      <h4>Lämpötilat</h4>
      <DiscreteColorLegend orientation="horizontal" width={300} items={ITEMS} />

      <XYPlot height={props.height * 0.3} width={props.width * 0.5} onMouseLeave={props.resetCrosshairValue}
              yPadding={20} xType="time">
        <YAxis />
        { props.graphData[0].length !== 0  ?
          <LineSeries color="blue" data={props.graphData[0]}
                      onNearestX={props.setCrosshairValue} />
          : null
        }
        { props.graphData[1].length !== 0 ?
          <LineSeries color="green" data={props.graphData[1]}
                      onNearestX={props.setCrosshairValue} />
          : null
        }
        { props.graphData[2].length !== 0 ?
          <LineSeries color="purple" data={props.graphData[2]}
                      onNearestX={props.setCrosshairValue} />
          : null
        }
        { props.graphData[3].length !== 0 ?
          <LineSeries color="red" data={props.graphData[3]}
                      onNearestX={props.setCrosshairValue} />
          : null
        }
        <Crosshair values={props.crosshairValue}>
          {crosshair}
        </Crosshair>
      </XYPlot>
    </div>
  );
}


class SensorDataCombine extends React.Component {
  constructor(props) {
    super(props);

    this.dataWorker = null;
    this.dataSenderWorker = null;
    this.errorValues = 0;
    this.sensorValueLoadingCount = 0

    const width = window.innerWidth
                  || document.documentElement.clientWidth
                  || document.body.clientWidth;

    const height = window.innerHeight
                  || document.documentElement.clientHeight
                  || document.body.clientHeight;

    this.sensorLocationKeys = {
      widthLeftValues: 'ULTRA1',
      widthRightValues: 'ULTRA2',
      deepnessLeftFrontValues: 'ULTRA3',
      deepnessRightFrontValues: 'ULTRA4',
      deepnessLeftBackValues: 'ULTRA5',
      deepnessRightBackValues: 'ULTRA6',
      temperatureLeftValues: 'TEMP1',
      temperatureMiddleValues: 'TEMP2',
      temperatureRightValues: 'TEMP3',
      temperatureREMValues: 'TEMP4'
    };

    this.defaultSensorLocations = {
      widthLeftValues: 'widthLeftValues',
      widthRightValues: 'widthRightValues',
      deepnessLeftFrontValues: 'deepnessLeftFrontValues',
      deepnessRightFrontValues: 'deepnessRightFrontValues',
      deepnessLeftBackValues: 'deepnessLeftBackValues',
      deepnessRightBackValues: 'deepnessRightBackValues',
      temperatureLeftValues: 'temperatureLeftValues',
      temperatureMiddleValues: 'temperatureMiddleValues',
      temperatureRightValues: 'temperatureRightValues',
      temperatureREMValues: 'temperatureREMValues'
    };

    this.sensorLocationNamesFinnish = {
      widthLeftValues: 'Vasen leveys anturi',
      widthRightValues: 'Oikea leveys anturi',
      deepnessLeftFrontValues: 'Vasen etummainen syvyys sensori',
      deepnessRightFrontValues: 'Oikea etummainen syvyys sensor',
      deepnessLeftBackValues: 'Vasen takimmainen syvyys sensori',
      deepnessRightBackValues: 'Oikea takimmainen syvyys sensori',
      temperatureLeftValues: 'Vasen lämpö sensori',
      temperatureMiddleValues: 'Keskimmäinen lämpö sensori',
      temperatureRightValues: 'Oikea lämpö sensori',
      temperatureREMValues: 'REM lämpö sensori'
    };

    this.state = {
      sensorDevices: [],
      selectedSensorDevice: '',
      nothingToSend: true,
      widthWireValues: [],
      widthLeftValues: [],
      widthRightValues: [],
      deepnessLeftBackValues: [],
      deepnessRightBackValues: [],
      deepnessLeftFrontValues: [],
      deepnessRightFrontValues: [],
      temperatureLeftValues: [],
      temperatureMiddleValues: [],
      temperatureRightValues: [],
      temperatureREMValues: [],
      deepnessCalibrationDate: null,
      deepnessCalibrationTime: null,
      deepnessLeftFrontCalibration: null,
      deepnessLeftBackCalibration: null,
      deepnessRightFrontCalibration: null,
      deepnessRightBackCalibration: null,
      reverseLeftDeepness: 0,
      reverseRightDeepness: 0,
      width: width,
      height: height,
      widthGraphData: [],
      deepnessGraphData: [[], []],
      temperatureGraphData: [[], [], [], []],
      crosshairValue: [],
      deepnessMax: localStorage['deepnessMax'] ? parseInt(localStorage['deepnessMax'], 10) : 20000,
      deepnessMin: localStorage['deepnessMin'] ? parseInt(localStorage['deepnessMin'], 10) : 0,
      widthWireMin: 0,
      widthWireMax: 0,
      widthWireRealMin: 0,
      widthWireRealMax: 0,
      widthMax: localStorage['widthMax'] ? parseInt(localStorage['widthMax'], 10) : 10,
      widthMin: localStorage['widthMin'] ? parseInt(localStorage['widthMin'], 10) : 0,
      widthMultiplier: 1,
      sensorInterval: localStorage['sensorInterval'] ? parseInt(localStorage['sensorInterval'], 10) : 60,
      sensorLocations: Object.assign({}, this.defaultSensorLocations),
      showSensorLocationOptions: localStorage['showSensorLocationOptions'] ? parseInt(localStorage['showSensorLocationOptions'], 10) : 0,
      widths: []
    };

    this.clearData = this.clearData.bind(this);
    this.isREMSite = this.isREMSite.bind(this);
    this.confirmSendSensorData = this.confirmSendSensorData.bind(this);
    this.sendSensorData = this.sendSensorData.bind(this);
    this.setCalibrationValues = this.setCalibrationValues.bind(this);
    this.calibrateDeepnessSensors = this.calibrateDeepnessSensors.bind(this);
    this.setWidthCrosshairValue = this.setWidthCrosshairValue.bind(this);
    this.setDeepnessCrosshairValue = this.setDeepnessCrosshairValue.bind(this);
    this.setTemperatureCrosshairValue = this.setTemperatureCrosshairValue.bind(this);
    this.resetCrosshairValue = this.resetCrosshairValue.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.startGettingSensorValues = this.startGettingSensorValues.bind(this);
    this.setSensorLocation = this.setSensorLocation.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);

    this.dataWorker = new WebWorker(worker);
    this.dataSenderWorker = new WebWorker(senderWorker);

    this.dataWorker.addEventListener('message', e => {
      if (typeof e.data === 'object') {
        this.setState({
          widthWireValues: e.data.widthWireValues,
          widthLeftValues: e.data.widthLeftValues,
          widthRightValues: e.data.widthRightValues,
          deepnessLeftBackValues: e.data.deepnessLeftBackValues,
          deepnessLeftFrontValues: e.data.deepnessLeftFrontValues,
          deepnessRightBackValues: e.data.deepnessRightBackValues,
          deepnessRightFrontValues: e.data.deepnessRightFrontValues,
          temperatureLeftValues: e.data.temperatureLeftValues,
          temperatureMiddleValues: e.data.temperatureMiddleValues,
          temperatureRightValues: e.data.temperatureRightValues,
          temperatureREMValues: e.data.temperatureREMValues,
          nothingToSend: e.data.length === 0,
          loadingSensorValues: false
        }, () => {
          this.calculateWidthValues();
          this.setCalibrationValues();
          this.calibrateDeepnessSensors();
          this.setTemperatureGraphData();
        });
      }
      else {
        const sensorValueLoadingCount = e.data;
        this.setState({sensorValueLoadingCount: sensorValueLoadingCount });
      }
    });

    this.dataSenderWorker.addEventListener('message', async e => {
      const data = e.data;

      if (data.length != null) {
        const dataURL = data[0];
        let values = data[1];

        let coordinates = [];

        for (let value of values) {
          if (value.latitude !== 0) {
            const converted = toETRSTM35FIN(value.latitude, value.longitude);

            coordinates.push({y: converted.y, x: converted.x});
          }
        }

        const roadNumber = this.props.selectedConstructionSite.get('road_number');

        const roadData = await getRoadDataFromCoordinates(coordinates, 10, roadNumber);

        for (let index in values) {
          if (values[index].latitude !== 0 && roadData[index] != null) {
            values[index].road_number = roadData[index].road;
            values[index].road_part = roadData[index].part;
            values[index].road_distance = roadData[index].distance;
          }

          values[index].construction_site_id = this.props.selectedConstructionSite.get('id');

          fetch(dataURL, 'POST', values[index]).catch((error) => {
            this.errorValues++;
          }).finally(() => {
            this.sensorValueLoadingCount++;
            this.setState({ sensorValueLoadingCount: this.sensorValueLoadingCount });

            if (this.sensorValueLoadingCount === this.state.sensorValueCount) {
              if (this.errorValues === 0) {
                this.props.showMessage('Suoritettu', 'Kaikki data liitetty', 'Success');
              }
              else {
                this.props.showMessage('Huom', this.errorValues + ' arvoa ei onnistettu liittämään. Kokeile myöhemmin uudelleen', 'Warning');
              }
      
              this.setState({ combining: false });
            }
          });
        }
      }
      else {
        this.sensorValueLoadingCount++;
        this.setState({ sensorValueLoadingCount: this.sensorValueLoadingCount });

        if (this.sensorValueLoadingCount === this.state.sensorValueCount) {
          if (this.errorValues === 0) {
            this.props.showMessage('Suoritettu', 'Kaikki data liitetty', 'Success');
          }
          else {
            this.props.showMessage('Huom', this.errorValues + ' arvoa ei onnistettu liittämään. Kokeile myöhemmin uudelleen', 'Warning');
          }
  
          this.setState({ combining: false });
        }
      }
    });

    this.isREMSite();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    this.dataWorker.terminate();
    this.dataSenderWorker.terminate();
  }

  updateDimensions() {
    const width = window.innerWidth
                  || document.documentElement.clientWidth
                  || document.body.clientWidth;

    const height = window.innerHeight
                  || document.documentElement.clientHeight
                  || document.body.clientHeight;

    this.setState({
      width: width,
      height: height
     });
  }

  componentDidUpdate(lastProps, lastState) {
    if (lastState.selectedSensorDevice !== this.state.selectedSensorDevice) {
      this.clearData();
    }

    if (lastProps.selectedConstructionSite !== this.props.selectedConstructionSite) {
      this.isREMSite();
    }

    if (lastProps.timeRangeStart !== this.props.timeRangeStart ||
      lastProps.timeRangeEnd !== this.props.timeRangeEnd) {
      this.getSensorDevices();
    }

    if (this.state.selectedSensorDevice === '') {
      return;
    }

    if (lastState.widthMin !== this.state.widthMin ||
        lastState.widthMax !== this.state.widthMax ||
        lastState.lengthBetweenWidthSensors !== this.state.lengthBetweenWidthSensors ||
        lastState.widthMultiplier !== this.state.widthMultiplier ||
        lastState.widthWireMin !== this.state.widthWireMin ||
        lastState.widthWireMax !== this.state.widthWireMax ||
        lastState.widthWireRealMin !== this.state.widthWireRealMin ||
        lastState.widthWireRealMax !== this.state.widthWireRealMax) {
      this.calculateWidthValues();
    }

    if (lastState.deepnessCalibrationDate !== this.state.deepnessCalibrationDate ||
        lastState.deepnessCalibrationTime !== this.state.deepnessCalibrationTime) {
      this.setCalibrationValues();
    }

    if (lastState.REM !== this.state.REM ||
        lastState.deepnessLeftFrontCalibration !== this.state.deepnessLeftFrontCalibration ||
        lastState.deepnessLeftBackCalibration !== this.state.deepnessLeftBackCalibration ||
        lastState.deepnessRightFrontCalibration !== this.state.deepnessRightFrontCalibration ||
        lastState.deepnessRightBackCalibration !== this.state.deepnessRightBackCalibration ||
        lastState.deepnessMin !== this.state.deepnessMin ||
        lastState.deepnessMax !== this.state.deepnessMax ||
        lastState.reverseLeftDeepness !== this.state.reverseLeftDeepness ||
        lastState.reverseRightDeepness !== this.state.reverseRightDeepness) {
      this.calibrateDeepnessSensors();
    }

    if (lastState.lengthBetweenWidthSensors !== this.state.lengthBetweenWidthSensors) {
      localStorage['lengthBetweenWidthSensors-' + this.state.selectedSensorDevice] = this.state.lengthBetweenWidthSensors;
    }

    if (lastState.reverseLeftDeepness !== this.state.reverseLeftDeepness) {
      localStorage['reverseLeftDeepness-' + this.state.selectedSensorDevice] = this.state.reverseLeftDeepness;
    }

    if (lastState.reverseRightDeepness !== this.state.reverseRightDeepness) {
      localStorage['reverseRightDeepness-' + this.state.selectedSensorDevice] = this.state.reverseRightDeepness;
    }

    if (lastState.widthWireMin !== this.state.widthWireMin) {
      localStorage['widthWireMin-' + this.state.selectedSensorDevice] = this.state.widthWireMin;
    }

    if (lastState.widthWireMax !== this.state.widthWireMax) {
      localStorage['widthWireMax-' + this.state.selectedSensorDevice] = this.state.widthWireMax;
    }
    if (lastState.widthWireRealMin !== this.state.widthWireRealMin) {
      localStorage['widthWireRealMin-' + this.state.selectedSensorDevice] = this.state.widthWireRealMin;
    }

    if (lastState.widthWireRealMax !== this.state.widthWireRealMax) {
      localStorage['widthWireRealMax-' + this.state.selectedSensorDevice] = this.state.widthWireRealMax;
    }
  }

  isREMSite() {
    if (this.props.selectedConstructionSite == null) {
      return;
    }

    const operationType = this.props.selectedConstructionSite.get('operation_type');

    if (operationType != null && operationType.toUpperCase().includes('REM')) {
      this.setState({ REM: true });
    }
    else {
      this.setState({ REM: false });
    }
  }

  arrayBufferToObject(blob) {
    return new Promise(function (resolved, rejected) {
      const file = new File([blob], 'data.csv');
      Papa.parse(file, {
        header: true,
        complete: function (results) {
          resolved(results.data)
        }
      });
    });
  }

  clearData() {
    this.setState({
      widthWireValues: [],
      widthLeftValues: [],
      widthRightValues: [],
      deepnessLeftBackValues: [],
      deepnessRightFrontValues: [],
      deepnessLeftFrontValues: [],
      deepnessRightBackValues: [],
      temperatureLeftValues: [],
      temperatureMiddleValues: [],
      temperatureRightValues: [],
      temperatureREMValues: [],
      nothingToSend: true
    });
  }

  async getSensorDevices() {
    if (this.props.timeRangeStart === '' ||
        this.props.timeRangeStart === this.props.timeRangeEnd) {
      this.setState({ sensorDevices: [] });
      return;
    }

    this.setState({
      loadingSensorDevices: true,
      selectedSensorDevice: ''
    });

    this.clearData();

    let startDate = new Date(this.props.timeRangeStart + 'Z');
    const timezoneOffset = startDate.getTimezoneOffset() / 60;
    startDate.setHours(startDate.getHours() + timezoneOffset);
    const startTime = startDate.toISOString();

    let endDate = new Date(this.props.timeRangeEnd + 'Z');
    endDate.setHours(endDate.getHours() + timezoneOffset);
    const endTime = endDate.toISOString();

    let csv;

    try {
      csv = await fetchSensorData(startTime, endTime);
    } catch(error) {
      this.setState({ loadingSensorDevices: false });
      this.props.showNotice('Yhteys virhe', 'Error');
    }

    if (csv == null) return;

    const data = await this.arrayBufferToObject(csv);
    const devices = data.filter(field => field['device'] != null);

    let deviceNames = [];

    for (let index in devices) {
      deviceNames[index] = devices[index]['device'];
    }

    this.setState({
      sensorDevices: deviceNames,
      loadingSensorDevices: false
    });
  }

  async getSensorValues() {
    let startDate = new Date(this.props.timeRangeStart + 'Z');
    const timezoneOffset = startDate.getTimezoneOffset() / 60;
    startDate.setHours(startDate.getHours() + timezoneOffset);
    const startTime = startDate.toISOString();

    let endDate = new Date(this.props.timeRangeEnd + 'Z');
    endDate.setHours(endDate.getHours() + timezoneOffset);
    const endTime = endDate.toISOString();

    let csv;

    try {
      csv = await fetchSensorData(startTime, endTime,
                                  this.state.selectedSensorDevice);
    } catch(error) {
      this.props.showNotice('Yhteys virhe', 'Error');
    }

    if (csv == null) return null;

    const sensorData = await this.arrayBufferToObject(csv);

    await this.getSensorLocationValues();

    const calibrationValues = await this.getSensorCalibrationValues();

    // Widths
    // Wire sensor
    const wireKey = 'VAIJERI';
    const widthWireValues = sensorData.filter(field => field['sensor_id'] && field['sensor_id'].includes(wireKey));

    this.setSensorCalibrationValues(calibrationValues, widthWireValues, wireKey);

    let widthLeftValues = [];
    let widthRightValues = [];

    console.log(widthWireValues);

    if (widthWireValues.length === 0) {
      // Ultra sensor
      const witdthLeftKey = this.sensorLocationKeys[this.state.sensorLocations['widthLeftValues']];
      widthLeftValues = sensorData.filter(field => field['sensor_id'] === witdthLeftKey);
      this.setSensorCalibrationValues(calibrationValues, widthLeftValues, witdthLeftKey);

      const widthRightKey = this.sensorLocationKeys[this.state.sensorLocations['widthRightValues']];
      widthRightValues = sensorData.filter(field => field['sensor_id'] === widthRightKey);
      this.setSensorCalibrationValues(calibrationValues, widthRightValues, widthRightKey);
    }
    //

    // Deepnesses
    const deepnessLeftFrontKey = this.sensorLocationKeys[this.state.sensorLocations['deepnessLeftFrontValues']];
    const deepnessLeftFrontValues = sensorData.filter(field => field['sensor_id'] === deepnessLeftFrontKey);
    this.setSensorCalibrationValues(calibrationValues, deepnessLeftFrontValues, deepnessLeftFrontKey);

    const deepnessRightFrontKey = this.sensorLocationKeys[this.state.sensorLocations['deepnessRightFrontValues']];
    const deepnessRightFrontValues = sensorData.filter(field => field['sensor_id'] === deepnessRightFrontKey);
    this.setSensorCalibrationValues(calibrationValues, deepnessRightFrontValues, deepnessRightFrontKey);

    const deepnessLeftBackKey = this.sensorLocationKeys[this.state.sensorLocations['deepnessLeftBackValues']];
    const deepnessLeftBackValues = sensorData.filter(field => field['sensor_id'] === deepnessLeftBackKey);
    this.setSensorCalibrationValues(calibrationValues, deepnessLeftBackValues, deepnessLeftBackKey);

    const deepnessRightBackKey = this.sensorLocationKeys[this.state.sensorLocations['deepnessRightBackValues']];
    const deepnessRightBackValues = sensorData.filter(field => field['sensor_id'] === deepnessRightBackKey);
    this.setSensorCalibrationValues(calibrationValues, deepnessRightBackValues, deepnessRightBackKey);

    // Temperatures
    
    /** 
    const test = sensorData.filter(field => field['master_ip'] === '192.168.19.2');
    const test2 = sensorData.filter(field => field['master_ip'] === '192.168.19.3');
    console.log(test);
    console.log(test2);
    */
    
    const temperatureLeftKey = this.sensorLocationKeys[this.state.sensorLocations['temperatureLeftValues']];
    const temperatureLeftValues = sensorData.filter(field => field['sensor_id'] === temperatureLeftKey);
    this.setSensorCalibrationValues(calibrationValues, temperatureLeftValues, temperatureLeftKey);

    const temperatureMiddleKey = this.sensorLocationKeys[this.state.sensorLocations['temperatureMiddleValues']];
    const temperatureMiddleValues = sensorData.filter(field => field['sensor_id'] === temperatureMiddleKey);
    this.setSensorCalibrationValues(calibrationValues, temperatureMiddleValues, temperatureMiddleKey);
      
    const temperatureRightKey = this.sensorLocationKeys[this.state.sensorLocations['temperatureRightValues']];
    const temperatureRightValues = sensorData.filter(field => field['sensor_id'] === temperatureRightKey);
    this.setSensorCalibrationValues(calibrationValues, temperatureRightValues, temperatureRightKey);

    const temperatureREMKey = this.sensorLocationKeys[this.state.sensorLocations['temperatureREMValues']];
    const temperatureREMValues = sensorData.filter(field => field['sensor_id'] === temperatureREMKey);
    this.setSensorCalibrationValues(calibrationValues, temperatureREMValues, temperatureREMKey);

    return {
      widthWireValues: widthWireValues,
      widthLeftValues: widthLeftValues,
      widthRightValues: widthRightValues,
      deepnessLeftBackValues: deepnessLeftBackValues,
      deepnessLeftFrontValues: deepnessLeftFrontValues,
      deepnessRightBackValues: deepnessRightBackValues,
      deepnessRightFrontValues: deepnessRightFrontValues,
      temperatureLeftValues: temperatureLeftValues,
      temperatureMiddleValues: temperatureMiddleValues,
      temperatureRightValues: temperatureRightValues,
      temperatureREMValues: temperatureREMValues
    };
  }

  async getSensorValuesWithPositions() {
    this.setState({
      loadingSensorValues: true,
      sensorValueLoadingCount: 0,
      sensorValuesCount: 0
    });

    this.sensorValueLoadingCount = 0;

    let sensorValues = await this.getSensorValues();
    let widthWireValues = sensorValues.widthWireValues;
    let widthLeftValues = sensorValues.widthLeftValues;
    let widthRightValues = sensorValues.widthRightValues;
    let deepnessLeftBackValues = sensorValues.deepnessLeftBackValues;
    let deepnessLeftFrontValues = sensorValues.deepnessLeftFrontValues;
    let deepnessRightBackValues = sensorValues.deepnessRightBackValues;
    let deepnessRightFrontValues = sensorValues.deepnessRightFrontValues;
    const temperatureLeftValues = sensorValues.temperatureLeftValues;
    const temperatureMiddleValues = sensorValues.temperatureMiddleValues;
    const temperatureRightValues = sensorValues.temperatureRightValues;
    const temperatureREMValues = sensorValues.temperatureREMValues;

    widthWireValues = widthWireValues.filter(width => {
      if (parseInt(width['_value']) < 30000) {
        return true
      }

      return false;
    });

    if (widthWireValues.length > 0) {
      let values = [];

      for (let value of widthWireValues) {
        values.push(parseInt(value['_value']));
      }

      console.log('Min wire value: ' + Math.min(...values));
      console.log('Max wire value: ' + Math.max(...values));
    }

    widthLeftValues = widthLeftValues.filter(width => {
      if (parseInt(width['_value']) < 32760) {
        return true
      }

      return false;
    });

    widthRightValues = widthRightValues.filter(width => {
      if (parseInt(width['_value']) < 32760) {
        return true
      }

      return false;
    });

    deepnessLeftBackValues = deepnessLeftBackValues.filter(deepness => {
      if (deepness['_value'] !== "32760") {
        return true
      }

      return false;
    });

    deepnessLeftFrontValues = deepnessLeftFrontValues.filter(deepness => {
      if (deepness['_value'] !== "32760") {
        return true
      }

      return false;
    });

    deepnessRightBackValues = deepnessRightBackValues.filter(deepness => {
      if (deepness['_value'] !== "32760") {
        return true
      }

      return false;
    });

    deepnessRightFrontValues = deepnessRightFrontValues.filter(deepness => {
      if (deepness['_value'] !== "32760") {
        return true
      }

      return false;
    });

    sensorValues.widthWireValues = widthWireValues;
    sensorValues.widthLeftValues = widthLeftValues;
    sensorValues.widthRightValues = widthRightValues;
    sensorValues.deepnessLeftBackValues = deepnessLeftBackValues;
    sensorValues.deepnessLeftFrontValues = deepnessLeftFrontValues;
    sensorValues.deepnessRightBackValues = deepnessRightBackValues;
    sensorValues.deepnessRightFrontValues = deepnessRightFrontValues;

    let maxLength = Math.max(widthLeftValues.length, widthRightValues.length,
      deepnessLeftBackValues.length, deepnessRightFrontValues.length, deepnessLeftFrontValues.length,
      deepnessRightBackValues.length, temperatureLeftValues.length, temperatureMiddleValues.length,
      temperatureRightValues.length, temperatureREMValues.length, widthWireValues.length);

    if (maxLength === 0) return;

    let positionCSV;
    
    let startDate = new Date(this.props.timeRangeStart + 'Z');
    const timezoneOffset = startDate.getTimezoneOffset() / 60;
    startDate.setHours(startDate.getHours() + timezoneOffset);
    const startTime = startDate.toISOString();

    let endDate = new Date(this.props.timeRangeEnd + 'Z');
    endDate.setHours(endDate.getHours() + timezoneOffset);
    endDate.setMinutes(endDate.getMinutes() + 5);
    const endTime = endDate.toISOString();

    try {
      positionCSV = await fetchSensorData(startTime, endTime, 
                                          this.state.selectedSensorDevice, true);
    } catch(error) {

    }

    const positions = await this.arrayBufferToObject(positionCSV);

    let latitudes = positions.filter(field => field['_field'] === 'lat');
    let longitudes = positions.filter(field => field['_field'] === 'lon');

    this.dataWorker.postMessage([
      sensorValues,
      latitudes,
      longitudes,
      maxLength,
      this.state.sensorInterval
    ]);

    this.setState({
      sensorValuesCount: maxLength,
      sensorValueLoadingCount: 0
    });
  }

  confirmSendSensorData() {
    const startDate = new Date(this.props.timeRangeStart);
    const startTime = startDate.getDate() + '.' + (startDate.getMonth() + 1) +
      '.' + startDate.getFullYear() + ' ' + paddedNumber(startDate.getHours()) +
      ':' + paddedNumber(startDate.getMinutes());

    const endDate = new Date(this.props.timeRangeEnd);
    const endTime = endDate.getDate() + '.' + (endDate.getMonth() + 1) +
      '.' + endDate.getFullYear() + ' ' + paddedNumber(endDate.getHours()) +
      ':' + paddedNumber(endDate.getMinutes());

    this.props.showConfirm('Yhdistetäänkö laitteen ' + this.state.selectedSensorDevice + 
      ' data ajalta ' + startTime + ' - ' + endTime + ' kohteeseen ' + this.props.selectedConstructionSite.get('name') + '?', 
      this.sendSensorData);
  }

  sendSensorData() {
    timer(0).then(async () => {
      if (this.state.widthLeftValues.length !== 0 &&
          (this.state.lengthBetweenWidthSensors == null ||
          this.state.lengthBetweenWidthSensors === '')) {
        this.props.showNotice('Täytä leveys väli', 'Warning');
        return;
      }

      if ((this.state.deepnessLeftBackValues.length !== 0 || 
          this.state.deepnessRightFrontValues.length !== 0) &&
          !this.state.deepnessCalibrationDone) {
        this.props.showNotice('Aseta syvyyys kalibrointi aika', 'Warning');
        return;
      }

      const widths = this.state.widths;
      const deepnessLeftBackValues =  this.state.deepnessLeftBackValues;
      const deepnessLeftFrontValues =  this.state.deepnessLeftFrontValues;
      const deepnessRightBackValues =  this.state.deepnessRightBackValues;
      const deepnessRightFrontValues =  this.state.deepnessRightFrontValues;
      const temperatureLeftValues =  this.state.temperatureLeftValues;
      const temperatureMiddleValues =  this.state.temperatureMiddleValues;
      const temperatureRightValues =  this.state.temperatureRightValues;
      const temperatureREMValues =  this.state.temperatureREMValues;

      const widthLength = widths.length;
      const deepnessLeftMaxLength = Math.min(deepnessLeftFrontValues.length, deepnessLeftBackValues.length);
      const deepnessRightMaxLength = Math.min(deepnessRightFrontValues.length, deepnessRightBackValues.length);


      const allLength = widthLength +
                        deepnessLeftMaxLength +
                        deepnessRightMaxLength +
                        temperatureLeftValues.length +
                        temperatureMiddleValues.length +
                        temperatureRightValues.length +
                        temperatureREMValues.length;

      this.setState({
        combining: true,
        sensorValueLoadingCount: 0,
        sensorValueCount: allLength - 1
      });

      const sensorValues = {
        widths: widths,
        deepnessLeftBackValues: deepnessLeftBackValues,
        deepnessLeftFrontValues: deepnessLeftFrontValues,
        deepnessRightBackValues: deepnessRightBackValues,
        deepnessRightFrontValues: deepnessRightFrontValues,
        temperatureLeftValues: temperatureLeftValues,
        temperatureMiddleValues: temperatureMiddleValues,
        temperatureRightValues: temperatureRightValues,
        temperatureREMValues: temperatureREMValues
      };

      let existingWidths;
      let existingDeepnesses;
      let existingTemperatures;

      try {
        const widthUrl = '/width?site=' + this.props.selectedConstructionSite.get('id')
            + '&timestart=' + this.props.timeRangeStart
            + '&timeend=' + this.props.timeRangeEnd;
        existingWidths = await fetch(widthUrl);

        const deepnessUrl = '/deepness?site=' + this.props.selectedConstructionSite.get('id')
            + '&timestart=' + this.props.timeRangeStart
            + '&timeend=' + this.props.timeRangeEnd;
        existingDeepnesses = await fetch(deepnessUrl);

        const temperatureUrl = '/temperature?site=' + this.props.selectedConstructionSite.get('id')
            + '&timestart=' + this.props.timeRangeStart
            + '&timeend=' + this.props.timeRangeEnd;
        existingTemperatures = await fetch(temperatureUrl);
      } catch(error) {
        this.props.showMessage('Virhe', 'Palvelimeen ei saatu yhteyttä', 'Error');
        this.setState({combining: false});
        return;
      }

      this.errorValues = 0;
      this.sensorValueLoadingCount = 0;

      this.dataSenderWorker.postMessage([
        sensorValues,
        existingWidths,
        existingDeepnesses,
        existingTemperatures,
        this.state.reverseLeftDeepness,
        this.state.reverseRightDeepness,
        this.state.leftDeepnessSensorDifference,
        this.state.rightDeepnessSensorDifference,
        this.state.deepnessMax,
        this.state.deepnessMin
      ]);
    });
  }

  changeState(propertyName, type, defaultValue, event) {
    const value = stateValueParser(event, type, defaultValue);

    if (value == null) {
      return;
    }
    
    this.setState({[propertyName]: value});

    if (propertyName === 'deepnessLeftFrontCalibration' ||
        propertyName === 'deepnessLeftBackCalibration' ||
        propertyName === 'deepnessRightFrontCalibration' ||
        propertyName === 'deepnessRightBackCalibration' ||
        propertyName === 'deepnessCalibrationDate' ||
        propertyName === 'deepnessCalibrationTime' ||
        propertyName === 'reverseLeftDeepness' ||
        propertyName === 'reverseRightDeepness') {
      return;
    }

    if (typeof(Storage) !== 'undefined') {
      localStorage[propertyName] = value;
    }
  }

  toggleDeepnessCalibrationInfo() {
    window.alert('Syötä ajankohta jolloin kone on ollut varmasti tasaisella alustalla (Paikka jossa, missä syvyys on ollut 0)');
  }

  calculateWidthValues() {
    let widths = [];
    let graphData = [];

    if (this.state.widthWireValues.length !== 0) {
      if (!this.state.widthWireMin &&
          !this.state.widthWireMax &&
          !this.state.widthWireRealMin &&
          !this.state.widthWireRealMax ) {
        return;
      }

      for (let sensorValue of this.state.widthWireValues) {
        let newValue = Object.assign({}, sensorValue);

        const widthWireMin = this.state.widthWireMin;
        const widthWireMax = this.state.widthWireMax;
        const widthWireRealMin = this.state.widthWireRealMin;
        const widthWireRealMax = this.state.widthWireRealMax;

        const range = widthWireMax - widthWireMin;

        if (range === 0) {
          return;
        }

        const percentageFromMax = (sensorValue['_value'] - widthWireMin) / range;
        let width = (widthWireRealMax - widthWireRealMin) * percentageFromMax + widthWireRealMin;
        width = Math.round(width / 100 * 100) / 100;

        if (width > this.state.widthMax && width < this.state.widthMin) {
          continue;
        }
        
        newValue['_value'] = width;

        const x = new Date(newValue['_time']).getTime();

        graphData.push({x: x, y: width});
        widths.push(newValue);
      }
    }
    else {
      const minLength = Math.min(this.state.widthLeftValues.length,
                                 this.state.widthRightValues.length);

      for (let i = 0; i < minLength; i++) {
          const left = parseInt(this.state.widthLeftValues[i]['_value'], 10) / 10;
          const right = parseInt(this.state.widthRightValues[i]['_value'], 10) / 10;

          let width = Math.round((this.state.lengthBetweenWidthSensors + left + right) / 100 * 100) / 100;

          if (width <= this.state.widthMax && width >= this.state.widthMin) {
            const newer = this.newestSensorValue(this.state.widthLeftValues[i],
                                                this.state.widthRightValues[i]);

            const x = new Date(newer['_time']).getTime();

            width *= this.state.widthMultiplier;

            graphData.push({x: x, y: width});
            newer['_value'] = width;
            widths.push(newer);
          }
      }
    }

    this.setState({
      widthGraphData: graphData,
      widths: widths
    });
  }

  async setCalibrationValues() {
    if (this.state.loadingSensorValues) {
      return;
    }

    localStorage['deepnessCalibrationDate-' + this.state.selectedSensorDevice] = this.state.deepnessCalibrationDate
    localStorage['deepnessCalibrationTime-' + this.state.selectedSensorDevice] = this.state.deepnessCalibrationTime;

    const calibrationTime = this.state.deepnessCalibrationDate + 'T' + 
                            this.state.deepnessCalibrationTime;
    let time = new Date(calibrationTime);

    if (isNaN(time.getTime())) {
      let deepnessLeftFrontCalibration ;
      let deepnessLeftBackCalibration;
      let deepnessRightFrontCalibration;
      let deepnessRightBackCalibration;

      if (localStorage['deepnessLeftFrontCalibration-' + this.state.selectedSensorDevice] != null) {
        deepnessLeftFrontCalibration = parseFloat(localStorage['deepnessLeftFrontCalibration-'
                                                    + this.state.selectedSensorDevice]);
      }

      if (localStorage['deepnessLeftBackCalibration-' + this.state.selectedSensorDevice] != null) {
        deepnessLeftBackCalibration = parseFloat(localStorage['deepnessLeftBackCalibration-'
                                                    + this.state.selectedSensorDevice]);
      }

      if (localStorage['deepnessRightFrontCalibration-' + this.state.selectedSensorDevice] != null) {
        deepnessRightFrontCalibration = parseFloat(localStorage['deepnessRightFrontCalibration-'
                                                    + this.state.selectedSensorDevice]);
      }

      if (localStorage['deepnessRightBackCalibration-' + this.state.selectedSensorDevice] != null) {
        deepnessRightBackCalibration = parseFloat(localStorage['deepnessRightBackCalibration-'
                                                    + this.state.selectedSensorDevice]);
      }

      this.setState({
        deepnessLeftFrontCalibration: deepnessLeftFrontCalibration || 0,
        deepnessLeftBackCalibration: deepnessLeftBackCalibration || 0,
        deepnessRightFrontCalibration: deepnessRightFrontCalibration || 0,
        deepnessRightBackCalibration: deepnessRightBackCalibration || 0
      });

      return;
    }

    const OFF_SET_MIN = 1; 

    time.setMinutes(time.getMinutes() - OFF_SET_MIN);
    const startTime = time.toISOString()

    time.setMinutes(time.getMinutes() + OFF_SET_MIN * 2);
    const endTime = time.toISOString()

    let csv;

    try {
      csv = await fetchSensorData(startTime, endTime, this.state.selectedSensorDevice, false);
    } catch(error) {
      this.props.showMessage('Virhe', 'Sensoridataa ei saatu palvelimelta', 'Error');
      return;
    }

    if (csv == null) return null;

    const sensorData = await this.arrayBufferToObject(csv);

    const deepnessLeftFrontValues = sensorData.filter(field => field['master_ip'] === '192.168.19.2'
      && field['sensor_id'] === this.sensorLocationKeys[this.state.sensorLocations['deepnessLeftFrontValues']]);

    const deepnessRightFrontValues = sensorData.filter(field => field['master_ip'] === '192.168.19.2'
      && field['sensor_id'] === this.sensorLocationKeys[this.state.sensorLocations['deepnessRightFrontValues']]);

    const deepnessLeftBackValues = sensorData.filter(field => field['master_ip'] === '192.168.19.2'
      && field['sensor_id'] === this.sensorLocationKeys[this.state.sensorLocations['deepnessLeftBackValues']]);

    const deepnessRightBackValues = sensorData.filter(field => field['master_ip'] === '192.168.19.2'
      && field['sensor_id'] === this.sensorLocationKeys[this.state.sensorLocations['deepnessRightFrontValues']]);

    let leftBack = 0;
    let leftFront = 0;
    let leftBackValue = [];
    let leftFrontValue = [];

    const maxLengthLeft = Math.max(deepnessLeftBackValues.length, deepnessLeftFrontValues.length);

    for (let index = 0; index < maxLengthLeft; index++) {
      if (deepnessLeftBackValues.length > index) {
        leftBackValue.push(parseFloat(deepnessLeftBackValues[index]['_value']));
      }

      if (deepnessLeftFrontValues.length > index) {
        leftFrontValue.push(parseFloat(deepnessLeftFrontValues[index]['_value']));
      }
    }

    if (leftBackValue.length !== 0) {
      leftBack = mode(leftBackValue);
    }

    if (leftFrontValue.length !== 0) {
      leftFront = mode(leftFrontValue);
    }

    let rightFront = 0;
    let rightBack = 0;
    let rightFrontValue = [];
    let rightBackValue = [];

    const maxLengthRight = Math.max(deepnessRightFrontValues.length, deepnessRightBackValues.length);

    for (let index = 0; index < maxLengthRight; index++) {
      if (deepnessRightFrontValues.length > index) {
        rightFrontValue.push(parseFloat(deepnessRightFrontValues[index]['_value']));
      }

      if (deepnessRightBackValues.length > index) {
        rightBackValue.push(parseFloat(deepnessRightBackValues[index]['_value']));
      }
    }

    if (rightFrontValue.length !== 0) {
      rightFront = mode(rightFrontValue);
    }

    if (rightBackValue.length !== 0) {
      rightBack = mode(rightBackValue);
    }

    this.setState({
      deepnessLeftFrontCalibration: leftFront,
      deepnessLeftBackCalibration: leftBack,
      deepnessRightFrontCalibration: rightFront,
      deepnessRightBackCalibration: rightBack
    });
  }

  async calibrateDeepnessSensors() {
    if (this.state.loadingSensorValues || 
      (this.state.deepnessLeftFrontValues.length === 0 && this.state.deepnessRightFrontValues.length === 0)){
      return;
    }

    const calibrationTime = this.state.deepnessCalibrationDate + 'T' + 
                            this.state.deepnessCalibrationTime;
    let time = new Date(calibrationTime);

    const leftFront = this.state.deepnessLeftFrontCalibration;
    const leftBack = this.state.deepnessLeftBackCalibration;
    const rightFront = this.state.deepnessRightFrontCalibration;
    const rightBack = this.state.deepnessRightBackCalibration;

    if (isNaN(time)) {
      localStorage['deepnessLeftFrontCalibration-' + this.state.selectedSensorDevice] = leftFront;
      localStorage['deepnessLeftBackCalibration-' + this.state.selectedSensorDevice] = leftBack;
      localStorage['deepnessRightFrontCalibration-' + this.state.selectedSensorDevice] = rightFront;
      localStorage['deepnessRightBackCalibration-' + this.state.selectedSensorDevice] = rightBack;
    }

    let leftDeepnessSensorDifference;
    let rightDeepnessSensorDifference;
    
    if (this.state.reverseLeftDeepness) {
      leftDeepnessSensorDifference = leftFront - leftBack;
    }
    else {
      leftDeepnessSensorDifference = leftBack - leftFront;
    }

    if (this.state.reverseRightDeepness) {
      rightDeepnessSensorDifference = rightFront - rightBack;
    }
    else {
      rightDeepnessSensorDifference = rightBack - rightFront;
    }

    const maxLength = Math.max(this.state.deepnessLeftFrontValues.length,
                               this.state.deepnessLeftBackValues.length,
                               this.state.deepnessRightFrontValues.length,
                               this.state.deepnessRightBackValues.length);

    let leftDeepnesses = [];
    let rightDeepnesses = [];

    let graphData = [[], []];

    for (let i = 0; i < maxLength; i++) {
      if (this.state.deepnessLeftBackValues[i] && this.state.deepnessLeftFrontValues[i]) {
        const frontLeft = parseFloat(this.state.deepnessLeftFrontValues[i]['_value']);
        const backLeft = parseFloat(this.state.deepnessLeftBackValues[i]['_value']);

        if (frontLeft != null && backLeft != null) {
          let deepness;

          if (this.state.reverseLeftDeepness) {
            deepness =  frontLeft - backLeft;
          }
          else {
            deepness =  backLeft - frontLeft;
          }

          deepness -= leftDeepnessSensorDifference;

          if (deepness <= this.state.deepnessMax && deepness >= this.state.deepnessMin) {
            leftDeepnesses.push(deepness);

            const newer = this.newestSensorValue(this.state.deepnessLeftBackValues[i],
                                                this.state.deepnessLeftFrontValues[i]);

            const x = new Date(newer['_time']).getTime();

            graphData[0].push({
              x: x,
              y: deepness,
              latitude: Math.round(this.state.deepnessLeftBackValues[i].location.latitude * 100000) / 100000,
              longitude: Math.round(this.state.deepnessLeftBackValues[i].location.longitude * 100000) / 100000,
            });
          }
        }
      }

      if (this.state.deepnessRightFrontValues[i] && this.state.deepnessRightBackValues[i]) {
        const frontRight = parseFloat(this.state.deepnessRightFrontValues[i]['_value']);
        const backRight = parseFloat(this.state.deepnessRightBackValues[i]['_value']);

        if (frontRight != null && backRight != null) {
          let deepness;

          if (this.state.reverseRightDeepness) {
            deepness = frontRight - backRight;
          }
          else {
            deepness = backRight - frontRight;
          }

          deepness -= rightDeepnessSensorDifference;

          if (deepness <= this.state.deepnessMax && deepness >= this.state.deepnessMin) {
            rightDeepnesses.push(deepness);

            const newer = this.newestSensorValue(this.state.deepnessRightFrontValues[i],
                                                 this.state.deepnessRightBackValues[i]);


            const x = new Date(newer['_time']).getTime();

            graphData[1].push({
              x: x,
              y: deepness,
              latitude: Math.round(this.state.deepnessRightFrontValues[i].location.latitude * 100000) / 100000,
              longitude: Math.round(this.state.deepnessRightFrontValues[i].location.longitude * 100000) / 100000,
            });
          }
        }
      }
    }

    const leftDeepnessesMode = mode(leftDeepnesses);
    const rightDeepnessesMode = mode(rightDeepnesses);

    this.props.showMessage('Ilmoitus',
      'Tällä kalibroinilla saataisiin yleisin arvot Vasen: '
     + (leftDeepnessesMode ? (leftDeepnessesMode + ' mm, (Sensoreiden ero olisi ' 
      + leftDeepnessSensorDifference + ' mm)') : '-') +
     ' Oikea: ' + (rightDeepnessesMode ? (rightDeepnessesMode + ' mm, (Sensoreiden ero olisi ' 
      + rightDeepnessSensorDifference + ' mm)') : '-') , 'Ok');

    this.setState({
      leftDeepnessSensorDifference: leftDeepnessSensorDifference,
      rightDeepnessSensorDifference: rightDeepnessSensorDifference,
      deepnessCalibrationDone: true,
      deepnessGraphData: graphData
    });
  }

  setTemperatureGraphData() {
    let graphData = [[], [], [], []];

    for (let value of this.state.temperatureLeftValues) {
      graphData[0].push({
        x: new Date(value['_time']).getTime(),
        y: value['_value'],
        latitude: Math.round(value.location.latitude * 100000) / 100000,
        longitude: Math.round(value.location.longitude * 100000) / 100000,
      });
    }

    for (let value of this.state.temperatureMiddleValues) {
      graphData[1].push({
        x: new Date(value['_time']).getTime(),
        y: value['_value'],
        latitude: Math.round(value.location.latitude * 100000) / 100000,
        longitude: Math.round(value.location.longitude * 100000) / 100000,
      });
    }

    for (let value of this.state.temperatureRightValues) {
      graphData[2].push({
        x: new Date(value['_time']).getTime(),
        y: value['_value'],
        latitude: Math.round(value.location.latitude * 100000) / 100000,
        longitude: Math.round(value.location.longitude * 100000) / 100000,
      });
    }

    for (let value of this.state.temperatureREMValues) {
      graphData[3].push({
        x: new Date(value['_time']).getTime(),
        y: value['_value'],
        latitude: Math.round(value.location.latitude * 100000) / 100000,
        longitude: Math.round(value.location.longitude * 100000) / 100000,
      });
    }

    this.setState({
      temperatureGraphData: graphData
    });
  }

  newestSensorValue(value, value2) {
    const date = new Date(value['_time']);
    const date2 = new Date(value2['_time']);
  
    if (date < date2) {
      return value2;
    }
  
    return value;
  }

  setWidthCrosshairValue(value) {
    const date = new Date(value.x);
    const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
      + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

    const width = this.state.widthGraphData.reverse().find(data => data.x === value.x);

    this.setState({
      crosshairValue: [{
         x: value.x,
         time: time,
         width: width.y
      }]
    });
  }

  setDeepnessCrosshairValue(value) {
    const date = new Date(value.x);
    const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
      + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

    const leftDeepness = this.state.deepnessGraphData[0].reverse().find(data => data.x === value.x);
    const rightDeepness = this.state.deepnessGraphData[1].reverse().find(data => data.x === value.x);

    this.setState({
      crosshairValue: [{
         x: value.x,
         time: time,
         leftDeepness: leftDeepness ? leftDeepness.y : '-',
         rightDeepness: rightDeepness ? rightDeepness.y : '-',
         latitude: leftDeepness ? leftDeepness.latitude : rightDeepness.latitude,
         longitude: leftDeepness ? leftDeepness.longitude : rightDeepness.longitude
      }]
    });
  }

  setTemperatureCrosshairValue(value) {
    const date = new Date(value.x);
    const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
      + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

      const left = this.state.temperatureGraphData[0].reverse().find(data => data.x >= value.x);
      const middle = this.state.temperatureGraphData[1].reverse().find(data => data.x >= value.x);
      const right = this.state.temperatureGraphData[2].reverse().find(data => data.x >= value.x);
      const REM = this.state.temperatureGraphData[3].reverse().find(data => data.x >= value.x);

      const temperature = [...this.state.temperatureGraphData[0], 
                           ...this.state.temperatureGraphData[1],
                           ...this.state.temperatureGraphData[2],
                           ...this.state.temperatureGraphData[3]]
                          .find(temperature => temperature.x === value.x);

    this.setState({
      crosshairValue: [{
         x: value.x,
         time: time,
         leftTemperature: left ? left.y : '-',
         middleTemperature: middle ? middle.y : '-',
         rightTemperature: right ? right.y : '-',
         REMTemperature: REM ? REM.y : '-',
         latitude: temperature ? temperature.latitude : '-',
         longitude: temperature ? temperature.longitude : '-'
      }]
    });
  }

  resetCrosshairValue() {
    this.setState({crosshairValue: []});
  }

  startGettingSensorValues() {
    this.setState({
      lengthBetweenWidthSensors: localStorage['lengthBetweenWidthSensors-' + this.state.selectedSensorDevice] ? 
        parseFloat(localStorage['lengthBetweenWidthSensors-' + this.state.selectedSensorDevice]) || null
        : null,

      deepnessCalibrationDate: localStorage['deepnessCalibrationDate-' + this.state.selectedSensorDevice] ? 
        localStorage['deepnessCalibrationDate-' + this.state.selectedSensorDevice]
        : null,

      deepnessCalibrationTime: localStorage['deepnessCalibrationTime-' + this.state.selectedSensorDevice] ? 
        localStorage['deepnessCalibrationTime-' + this.state.selectedSensorDevice]
        : null,

      reverseLeftDeepness: integerValue(localStorage['reverseLeftDeepness-' + this.state.selectedSensorDevice], 0),

      reverseRightDeepness: integerValue(localStorage['reverseRightDeepness-' + this.state.selectedSensorDevice], 0),

      widthWireMin: integerValue(localStorage['widthWireMin-' + this.state.selectedSensorDevice], 0),
      widthWireMax: integerValue(localStorage['widthWireMax-' + this.state.selectedSensorDevice], 0),
      widthWireRealMin: integerValue(localStorage['widthWireRealMin-' + this.state.selectedSensorDevice], 0),
      widthWireRealMax: integerValue(localStorage['widthWireRealMax-' + this.state.selectedSensorDevice], 0)
    });

    this.getSensorValuesWithPositions();
  }

  setSensorLocation(sensor, event) {
    const location = stateValueParser(event, 'string', null);
    
    let sensorLocations = this.state.sensorLocations;

    sensorLocations[sensor] = location;

    this.setState({
      sensorLocations: sensorLocations,
      widthLeftValues: [],
      widthRightValues: [],
      deepnessLeftBackValues: [],
      deepnessRightFrontValues: [],
      deepnessLeftFrontValues: [],
      deepnessRightBackValues: [],
      temperatureLeftValues: [],
      temperatureMiddleValues: [],
      temperatureRightValues: [],
      temperatureREMValues: [],
      nothingToSend: true
    });
  }

  async getSensorLocationValues() {
    const locationData = await fetch('/sensor/location/' + this.state.selectedSensorDevice);

    let sensorLocations = this.defaultSensorLocations;

    for (let location of locationData) {
      let sensor;

      for (const [key, value] of Object.entries(this.sensorLocationKeys)) {
        if (value === location.sensor) {
          sensor = key;
          break;
        }
      }

      sensorLocations[location.location] = sensor;
      sensorLocations[sensor] = location.location;
    }

    await new Promise(resolve => this.setState({
      sensorLocations: sensorLocations,
    }, resolve));
  }

  async getSensorCalibrationValues() {
    return await fetch('/sensor/calibration/' + this.state.selectedSensorDevice);
  }

  setSensorCalibrationValues(calibrationValues, sensorValues, sensorKey) {
    const calibration = calibrationValues.find(value => value.sensor === sensorKey);

    if (calibration == null) {
      return;
    }

    for (let sensorValue of sensorValues) {
      sensorValue['_value'] = parseInt(sensorValue['_value']) + calibration.calibration;
    }
  }

  render() {
    return (
      <div>
        <div className="center">
          <h1>Liitä sensoridata kohteeseen</h1>
        </div>
        <div className={this.state.loadingSensorValues ? 'container disabled' : 'container'}>
          <div className="row">
            <div className="column">
              <ContractSelect store={this.props.store} />
            </div>
            <div className="column">
              <ConstructionSiteSelect store={this.props.store} />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <TimeRange store={this.props.store} />
            </div>
            <div className="column">
              { this.state.loadingSensorDevices ?
                <div className='loader' />
              :
                <label>
                  Sensorilaite
                  <select onChange={this.changeState.bind(this, 'selectedSensorDevice', 'string', '')} 
                          value={this.state.selectedSensorDevice} >
                  <option value={''}>Valitse laite</option>
                    { this.state.sensorDevices.map(device => (
                        <option key={device} value={device}>
                          {device}
                        </option>
                      ))
                    }
                  </select>
                </label>
              }
            </div>
            <div className='column'>
              <label htmlFor='sensorInterval'>Arvojen välitys (esim. 1 = sekuntti ja 60 = minuutin välein)</label>
              <input id='sensorInterval' type='tel'
                      className={this.state.sensorInterval ? '' : 'required'}
                      value={this.state.sensorInterval || ''}
                      onChange={this.changeState.bind(this, 'sensorInterval', 'integer', 0)} />
            </div>
          </div>

          <div className="row">
            <div className='column'>
              Näytä sensori paikka asetukset:
              <br/>
              <label>
                Ei
                <input type='radio' name='showSensorLocationOptions' value={0}
                        onChange={this.changeState.bind(this, 'showSensorLocationOptions', 'integer', 0)}
                        checked={this.state.showSensorLocationOptions === 0} />
              </label>
              <label>
                Kyllä
                <input type='radio' name='showSensorLocationOptions' value={1}
                        onChange={this.changeState.bind(this, 'showSensorLocationOptions', 'integer', 1)}
                        checked={this.state.showSensorLocationOptions === 1} />
              </label>
            </div>

            { this.state.showSensorLocationOptions ?
              <div className='column'>
                { Object.entries(this.defaultSensorLocations).map(([key, value]) => {
                  return (
                    <label key={key} className='sensor-location-select'>
                      { this.sensorLocationNamesFinnish[key] }
                      <select onChange={this.setSensorLocation.bind(this, key)} 
                              value={this.state.sensorLocations[key]}>
                        { Object.entries(this.defaultSensorLocations).map(([locationKey, locationvalue]) => (
                            <option key={this.defaultSensorLocations[locationKey]} value={this.defaultSensorLocations[locationKey]}>
                              {this.sensorLocationNamesFinnish[this.defaultSensorLocations[locationKey]]}
                            </option>
                          ))
                        }
                      </select>
                    </label>
                  );
                })
                }
              </div>
              :
              null
            }
          </div>

          <div>
            { this.state.widthWireValues.length !== 0 ?
              <div className='row'>
                <div className='column'>
                  <label htmlFor='widthWireMin'>Leveys sensorin minimi (Sensori arvo)</label>
                  <input id='widthWireMin' type='number'
                          className={this.state.widthWireMin ? '' : 'required'}
                          value={this.state.widthWireMin || ''}
                          onChange={this.changeState.bind(this, 'widthWireMin', 'integer', 0)} />
                </div>
                <div className='column'>
                  <label htmlFor='widthWireMax'>Leveys sensorin maksimi (Sensori arvo)</label>
                  <input id='widthWireMax' type='number'
                          className={this.state.widthWireMax ? '' : 'required'}
                          value={this.state.widthWireMax || ''}
                          onChange={this.changeState.bind(this, 'widthWireMax', 'integer', 0)} />
                </div>
                <div className='column'>
                  <label htmlFor='widthWireRealMin'>Leveys sensorin minimi (Oikea arvo cm)</label>
                  <input id='widthWireRealMin' type='number'
                          className={this.state.widthWireRealMin ? '' : 'required'}
                          value={this.state.widthWireRealMin || ''}
                          onChange={this.changeState.bind(this, 'widthWireRealMin', 'integer', 0)} />
                </div>
                <div className='column'>
                  <label htmlFor='widthWireRealMax'>Leveys sensorin maksimi (Oikea arvo cm)</label>
                  <input id='widthWireRealMax' type='number'
                          className={this.state.widthWireRealMax ? '' : 'required'}
                          value={this.state.widthWireRealMax || ''}
                          onChange={this.changeState.bind(this, 'widthWireRealMax', 'integer', 0)} />
                </div>
              </div>
              :
              null
            }
            { this.state.widthLeftValues.length !== 0 ?
              <div className='row'>
                <div className='column'>
                  <label htmlFor='lengthBetweenWidthSensors'>Leveys sensoreiden väli (cm)</label>
                  <input id='lengthBetweenWidthSensors' type='tel'
                          className={this.state.lengthBetweenWidthSensors ? '' : 'required'}
                          value={this.state.lengthBetweenWidthSensors || ''}
                          onChange={this.changeState.bind(this, 'lengthBetweenWidthSensors', 'float', 0.0)} />
                </div>
                <div className='column'>
                  <label htmlFor='widthMin'>Leveyden minimi filtteri (m)</label>
                  <input id='widthMin' type='tel'
                        value={this.state.widthMin}
                        onChange={this.changeState.bind(this, 'widthMin', 'float', 0.0)} />
                </div>
                <div className='column'>
                  <label htmlFor='widthMax'>Leveyden maksimi filtteri (m)</label>
                  <input id='widthMax' type='tel'
                        value={this.state.widthMax}
                        onChange={this.changeState.bind(this, 'widthMax', 'float', 0.0)} />
                </div>
                <div className='column'>
                  <label htmlFor='widthMultiplier'>Leveys kerroin</label>
                  <input id='widthMultiplier' type='tel'
                        value={this.state.widthMultiplier}
                        onChange={this.changeState.bind(this, 'widthMultiplier', 'float', 1.0)} />
                </div>
              </div>
              : null
            }
            { this.state.deepnessLeftFrontValues.length !== 0 || this.state.deepnessRightFrontValues.length !== 0 ?
            <div>
              <div className='row'>
                <div className='column'>
                  <label htmlFor='deepnessCalibrationDate'>
                  0 tason aika:
                  <div className={'info'}
                      onClick={this.toggleDeepnessCalibrationInfo}>
                    ?
                  </div>
                  </label>
                  <input type='date' id='deepnessCalibrationDate'
                        value={this.state.deepnessCalibrationDate || ''}
                        onChange={this.changeState.bind(this, 'deepnessCalibrationDate', 'time', null)} />
                  <input type='time' id='deepnessCalibrationTime'
                        value={this.state.deepnessCalibrationTime || ''}
                        onChange={this.changeState.bind(this, 'deepnessCalibrationTime', 'time', null)} />
                </div>
                <div className='column'>
                  <label htmlFor='deepnessMin'>Syvyyden minimi filtteri (mm)</label>
                  <input id='deepnessMin' type='tel'
                        value={this.state.deepnessMin}
                        onChange={this.changeState.bind(this, 'deepnessMin', 'float', 0.0)} />
                </div>
                <div className='column'>
                  <label htmlFor='deepnessMax'>Syvyyden maksimi filtteri (mm)</label>
                  <input id='deepnessMax' type='tel'
                        value={this.state.deepnessMax}
                        onChange={this.changeState.bind(this, 'deepnessMax', 'float', 0.0)} />
                </div>
              </div>
              { this.state.deepnessLeftFrontValues.length !== 0 ?
                <div>
                  <div className='row'>
                    <div className='column'>
                      <label htmlFor='deepnessLeftFrontCalibration'>Vasen etummainen 0 tasossa syvyys (mm)</label>
                      <input id='deepnessLeftFrontCalibration' type='tel'
                            value={this.state.deepnessLeftFrontCalibration}
                            onChange={this.changeState.bind(this, 'deepnessLeftFrontCalibration', 'float', 0.0)} />
                    </div>
                    <div className='column'>
                      <label htmlFor='deepnessLeftBackCalibration'>Vasen takimmainen 0 tasossa syvyys (mm)</label>
                      <input id='deepnessLeftBackCalibration' type='tel'
                            value={this.state.deepnessLeftBackCalibration}
                            onChange={this.changeState.bind(this, 'deepnessLeftBackCalibration', 'float', 0.0)} />
                    </div>
                    <div className='column'>
                      Käännä vasemmat syvyys sensorit:
                      <br/>
                      <label>
                        Ei
                        <input type='radio' name='reverseLeftDeepness' value={0}
                                onChange={this.changeState.bind(this, 'reverseLeftDeepness', 'integer', 0)}
                                checked={this.state.reverseLeftDeepness === 0} />
                      </label>
                      <label>
                        Kyllä
                        <input type='radio' name='reverseLeftDeepness' value={1}
                                onChange={this.changeState.bind(this, 'reverseLeftDeepness', 'integer', 1)}
                                checked={this.state.reverseLeftDeepness === 1} />
                      </label>
                    </div>
                  </div>
                </div>
                : null
              }
              { this.state.deepnessRightFrontValues.length !== 0 ?
                <div>
                  <div className='row'>
                    <div className='column'>
                      <label htmlFor='deepnessRightFrontCalibration'>Oikea etummainen 0 tasossa syvyys (mm)</label>
                      <input id='deepnessRightFrontCalibration' type='tel'
                            value={this.state.deepnessRightFrontCalibration}
                            onChange={this.changeState.bind(this, 'deepnessRightFrontCalibration', 'float', 0.0)} />
                    </div>
                    <div className='column'>
                      <label htmlFor='deepnessRightBackCalibration'>Oikea takimmainen 0 tasossa syvyys (mm)</label>
                      <input id='deepnessRightBackCalibration' type='tel'
                            value={this.state.deepnessRightBackCalibration}
                            onChange={this.changeState.bind(this, 'deepnessRightBackCalibration', 'float', 0.0)} />
                    </div>
                    <div className='column'>
                      Käännä oikea syvyys sensorit:
                      <br/>
                      <label>
                        Ei
                        <input type='radio' name='reverseRightDeepness' value={0}
                                onChange={this.changeState.bind(this, 'reverseRightDeepness', 'integer', 0)}
                                checked={this.state.reverseRightDeepness === 0} />
                      </label>
                      <label>
                        Kyllä
                        <input type='radio' name='reverseRightDeepness' value={1}
                                onChange={this.changeState.bind(this, 'reverseRightDeepness', 'integer', 1)}
                                checked={this.state.reverseRightDeepness === 1} />
                      </label>
                    </div>
                  </div>
                </div>
                : null
             }
            </div>
            : null
          }
          </div>
          { this.state.loadingSensorValues ?
            <div className='center'>
              <div className='loader' />
              { this.state.sensorValuesCount ? 
                this.state.sensorValueLoadingCount + ' / ' + this.state.sensorValuesCount
                :
                null
              }
            </div>
          :
          ( this.state.nothingToSend ?
            <button onClick={this.startGettingSensorValues}
                    disabled={this.state.selectedSensorDevice === '' ||
                              this.state.sensorInterval === 0}>
              Hae
            </button>
            :
            <div>
              <WidthGraph graphData={this.state.widthGraphData}
                          crosshairValue={this.state.crosshairValue}
                          setCrosshairValue={this.setWidthCrosshairValue}
                          resetCrosshairValue={this.resetCrosshairValue}
                          width={this.state.width} height={this.state.height} />
              <DeepnessGraph graphData={this.state.deepnessGraphData}
                             crosshairValue={this.state.crosshairValue}
                             setCrosshairValue={this.setDeepnessCrosshairValue}
                             resetCrosshairValue={this.resetCrosshairValue}
                             width={this.state.width} height={this.state.height} />
              <TemperatureGraph graphData={this.state.temperatureGraphData}
                                crosshairValue={this.state.crosshairValue}
                                setCrosshairValue={this.setTemperatureCrosshairValue}
                                resetCrosshairValue={this.resetCrosshairValue}
                                width={this.state.width} height={this.state.height} />
              <button onClick={this.confirmSendSensorData}>
                Liitä
              </button>
            </div>
          )
          }
        </div>
        <CombineLoader show={this.state.combining}
                       sensorValueLoadingCount={this.state.sensorValueLoadingCount}
                       sensorValueCount={this.state.sensorValueCount} />
      </div>
    );
  }
}

export default connect(state => ({
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  timeRangeStart: state.timeRange.get('startTime'),
  timeRangeEnd: state.timeRange.get('endTime'),
  organizationId: state.login.get('user') ? state.login.get('user').get('organizationId') : null,
}), { showMessage, showConfirm, showNotice })(SensorDataCombine);
